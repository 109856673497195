import React from 'react';
import { Button, Icon, Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'Headache', text: 'Headache', value: 'Headache' },
  { key: 'Depression', text: 'Depression', value: 'Depression' },
];

const pages = [
  {
    key: 'page1',
    text: 'Paracetamol and pregnancy',
    value: 'Paracetamol and pregnancy',
  },
  { key: 'page2', text: 'Paracetamol', value: 'Paracetamol' },
  { key: 'page3', text: 'Pregnancy', value: 'Pregnancy' },
];

class MedicalConditionsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      currentValue: 'Depression',
      page: 'Paracetamol and pregnancy',
      options,
      pages,
    };
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
  }
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });
  edit(e) {
    e.preventDefault();
    this.setState({ editing: true });
  }
  save(e) {
    e.preventDefault();
    this.setState({ editing: false });
  }
  handleChangePage = (e, { value }) => this.setState({ page: value });
  render() {
    const { editing, currentValue, page } = this.state;
    const { pinned } = this.props;

    let pinnedClass = 'table-medical-conditions__td';
    if (pinned) {
      pinnedClass = 'table-medical-conditions__td pin-ico';
    }
    return (
      <div className='table-medical-conditions__tr'>
        <div className='table-medical-conditions__td'>1</div>
        <div className={pinnedClass}>
          {!editing && <span>{page}</span>}
          {editing && (
            <Dropdown
              options={this.state.pages}
              placeholder='Choose'
              search
              selection
              value={page}
              onChange={this.handleChangePage}
            />
          )}
          <div className='only-mobile'>
            {!editing && (
              <div>
                <Button icon>
                  <Icon color='teal' name='long arrow alternate up' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='long arrow alternate down' />
                </Button>
                <Button icon onClick={this.edit}>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className='table-medical-conditions__td'>
          {!editing && (
            <div>
              <div className='table-medical-conditions__mobile-desc'>
                Indication
              </div>

              <span className='label'>{currentValue}</span>
            </div>
          )}
          {editing && (
            <Dropdown
              options={this.state.options}
              placeholder='Choose'
              search
              selection
              allowAdditions
              value={currentValue}
              onAddItem={this.handleAddition}
              onChange={this.handleChange}
            />
          )}
        </div>
        <div className='table-medical-conditions__td'>
          <div className='only-desktop'>
            {!editing && (
              <div>
                <Button icon>
                  <Icon color='teal' name='long arrow alternate up' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='long arrow alternate down' />
                </Button>
                <Button icon onClick={this.edit}>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            )}
            {editing && (
              <div>
                <button
                  className='table-medical-conditions__cancel'
                  onClick={this.save}
                >
                  Cancel
                </button>
                <button
                  className='table-medical-conditions__save'
                  onClick={this.save}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <div className='only-mobile'>
            {editing && (
              <div>
                <Button basic onClick={this.save}>
                  Cancel
                </Button>
                <Button color='teal' onClick={this.save}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MedicalConditionsItem;
