import React from 'react';
import TabMenuRow from '../components/TabMenuRow';
import { Button, Icon } from 'semantic-ui-react';

class TabMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className='website-tabs__content'>
          <div className='table-menu'>
            <div className='table-menu__header'>
              <div className='table-menu__ttl'>Header menu</div>
              <Button color='teal'>
                <Icon name='plus' /> Add New
              </Button>
            </div>
            <div className='table-menu__head'>
              <div className='table-menu__tr'>
                <div className='table-menu__td table-menu__td--th'>Nr.</div>
                <div className='table-menu__td table-menu__td--th'>Name</div>
                <div className='table-menu__td table-menu__td--th'>Page</div>
                <div className='table-menu__td table-menu__td--th'></div>
              </div>
            </div>
            <div className='table-menu__body'>
              <TabMenuRow />
              <TabMenuRow />
              <TabMenuRow />
            </div>
          </div>

          <div className='table-menu'>
            <div className='table-menu__header'>
              <div className='table-menu__ttl'>Footer menu</div>
              <Button color='teal'>
                <Icon name='plus' /> Add New
              </Button>
            </div>
            <div className='table-menu__head'>
              <div className='table-menu__tr'>
                <div className='table-menu__td table-menu__td--th'>Nr.</div>
                <div className='table-menu__td table-menu__td--th'>Name</div>
                <div className='table-menu__td table-menu__td--th'>Page</div>
                <div className='table-menu__td table-menu__td--th'></div>
              </div>
            </div>
            <div className='table-menu__body'>
              <TabMenuRow />
              <TabMenuRow />
              <TabMenuRow />
            </div>
          </div>
        </div>
        <div className='website-tabs__footer website-tabs__footer--space-between'>
          <div>
            <Button basic>Cancel</Button>
          </div>
          <div>
            <Button basic>Preview</Button>
            <Button color='teal'>Save</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TabMenu;
