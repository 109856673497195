import React from 'react';
import { Button } from 'semantic-ui-react';

class TabOther extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className='website-tabs__content'>
          <div className='table-logo'>
            <div className='table-logo__header'>Footer logo</div>
            <div className='table-logo__body'>
              <div className='table-logo__tr'>
                <div className='table-logo__td'>
                  <div className='file-upload'>
                    <input
                      type='file'
                      className='file-upload__input'
                      accept='.png, .jpg, .jpeg, .svg'
                    />
                    <div className='file-upload__text'>
                      Accepted formats png, jpg or svg
                    </div>
                    <div className='file-upload__btn'></div>
                  </div>
                </div>
                <div className='table-logo__td'>
                  <button className='table-medical-conditions__cancel'>
                    Cancel
                  </button>
                  <button className='table-medical-conditions__save'>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='website-tabs__footer  website-tabs__footer--space-between'>
          <div>
            <Button basic>Cancel</Button>
          </div>
          <div>
            <Button basic>Preview</Button>
            <Button color='teal'>Save</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TabOther;
