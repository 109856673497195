import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import KnowledgeRow from '../components/KnowledgeRow';
import { DatePickerInput } from 'rc-datepicker';
import { Icon, Input, Button, Pagination, Dropdown } from 'semantic-ui-react';

const pages = [
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 'All',
    text: 'All',
    value: 'All',
  },
];

const statuses = [
  {
    key: 'published',
    text: 'Published',
    value: 'Published',
  },
  {
    key: 'in-review',
    text: 'In review',
    value: 'In review',
  },
  {
    key: 'concept',
    text: 'Concept',
    value: 'Concept',
  },
  {
    key: 'removed',
    text: 'Removed',
    value: 'Removed',
  },
];

const translations = [
  {
    key: 'en',
    text: 'English',
    value: 'English',
  },
  {
    key: 'nl',
    text: 'Dutch',
    value: 'Dutch',
  },
  {
    key: 'be',
    text: 'Belgian',
    value: 'Belgian',
  },
];
class Knowledge extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 10, date1: '', date2: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.onChangeDate1 = this.onChangeDate1.bind(this);
    this.onChangeDate2 = this.onChangeDate2.bind(this);
  }
  handleChange2 = (e, { status }) => this.setState({ status });
  handleChange2 = (e, { translation }) => this.setState({ translation });
  handleChange = (e, { page }) => this.setState({ page });
  onChangeDate1(jsDate, dateString) {
    this.setState((state) => ({
      date1: dateString,
    }));
  }
  onChangeDate2(jsDate, dateString) {
    this.setState((state) => ({
      date2: dateString,
    }));
  }
  render() {
    const { page, status, translation, date1, date2 } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Knowledge pages</div>
            <Button color='teal'>
              <Icon name='plus'></Icon> Create
            </Button>
          </div>
          <div className='app-content__body'>
            <div className='knowledge'>
              <div className='knowledge__header'>
                <div className='knowledge__body'>
                  <div className='knowledge__filter'>
                    <div className='knowledge__search'>
                      <div className='search-filter'>
                        <Input placeholder='Search page'></Input>
                        <Button color='teal'>Search</Button>
                      </div>
                    </div>

                    <span>Filter by:</span>
                    <Dropdown
                      selection
                      placeholder='Status'
                      options={statuses}
                      onChange={this.handleChange2}
                      value={status}
                    />
                    <Dropdown
                      selection
                      placeholder='Translation'
                      options={translations}
                      onChange={this.handleChange3}
                      value={translation}
                    />
                    <DatePickerInput
                      showOnInputClick
                      placeholder='From'
                      value={date1}
                      onChange={this.onChangeDate1}
                    />
                    <DatePickerInput
                      showOnInputClick
                      placeholder='To'
                      value={date2}
                      onChange={this.onChangeDate2}
                    />
                  </div>
                  <div className='table-knowledge'>
                    <div className='table-knowledge__head'>
                      <div className='table-knowledge__tr'>
                        <div className='table-knowledge__td table-knowledge__td--th'>
                          Nr.
                        </div>
                        <div className='table-knowledge__td table-knowledge__td--th'>
                          Title
                          <div className='sort-icon'>
                            <Icon name='long arrow alternate up' />
                            <Icon name='long arrow alternate down' />
                          </div>
                        </div>
                        <div className='table-knowledge__td table-knowledge__td--th'>
                          Translation
                        </div>
                        <div className='table-knowledge__td table-knowledge__td--th'>
                          Date created
                          <div className='sort-icon'>
                            <Icon name='long arrow alternate up' />
                            <Icon name='long arrow alternate down' />
                          </div>
                        </div>
                        <div className='table-knowledge__td table-knowledge__td--th'>
                          Last updated
                          <div className='sort-icon'>
                            <Icon name='long arrow alternate up' />
                            <Icon name='long arrow alternate down' />
                          </div>
                        </div>
                        <div className='table-knowledge__td table-knowledge__td--th'>
                          Last updated by
                        </div>
                      </div>
                    </div>
                    <div className='table-knowledge__body'>
                      <KnowledgeRow num={1} />
                      <KnowledgeRow num={2} />
                      <KnowledgeRow num={3} />
                      <KnowledgeRow num={4} />
                      <KnowledgeRow num={5} />
                      <KnowledgeRow num={6} />
                      <KnowledgeRow num={7} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-pagination'>
              <span className='table-pagination__text'>Showing results:</span>
              <Dropdown
                selection
                options={pages}
                onChange={this.handleChange}
                value={page}
              />
              <span className='table-pagination__text'>Page</span>
              <Pagination
                activePage={1}
                boundaryRange={0}
                siblingRange={1}
                totalPages={22}
                ellipsisItem={undefined}
                firstItem={null}
                lastItem={null}
                prevItem={undefined}
                nextItem={undefined}
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default Knowledge;
