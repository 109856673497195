import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import BgImage from '../images/img-in-focus-2.jpg';

class InFocusRow extends React.Component {
  render() {
    const { pinned, order } = this.props;
    let pinClass = 'table-in-focus__td';

    if (pinned) {
      pinClass = 'table-in-focus__td pin-ico';
    }
    return (
      <div className='table-in-focus__tr'>
        <div className='table-in-focus__td'>{order}</div>
        <div className={pinClass}>
          <div
            className='table-in-focus__preview'
            style={{ backgroundImage: `url(${BgImage})` }}
          ></div>
        </div>
        <div className='table-in-focus__td'>
          <div className='table-in-focus__mobile-ttl'>Title</div>
          <span>Nam dapibus nisl vitae elit fringilla rutrum</span>
        </div>
        <div className='table-in-focus__td'>
          <div className='table-in-focus__mobile-ttl'>Date created</div>
          12/12/2019
        </div>
        <div className='table-in-focus__td'>
          <div className='table-in-focus__action'>
            <Button icon>
              <Icon color='teal' name='long arrow alternate up' />
            </Button>
            <Button icon>
              <Icon color='teal' name='long arrow alternate down' />
            </Button>
            <Button icon>
              <Icon color='teal' name='pencil' />
            </Button>
            <Button icon>
              <Icon color='teal' name='trash alternate outline' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default InFocusRow;
