import React from 'react';
import { Button } from 'semantic-ui-react';
import MaternalConditionsItem from './MaternalConditionsItem';
import { BrowserRouter as Router, Link } from 'react-router-dom';

function MaternalConditions() {
    return (
        <section className='maternal-conditions'>
            <div className='container'>
                <div className='maternal-conditions__box'>
                    <div className='maternal-conditions__ttl'>
                        Maternal medical conditions
          </div>
                    <ul className='maternal-conditions__list'>
                        <MaternalConditionsItem title="Rheumatoid Arthritis" tag="Rheumatoid Arthritis" 
                            contentshort="Rheumatoid arthritis (RA) is a kind of arthritis that mainly affects your joints. It is an autoimmune condition..." 
                            contentdetails="<b>What is rheumatoid arthritis?</b> <br/><br/>
                                Rheumatoid arthritis (RA) is a kind of arthritis that mainly affects your joints. It is an autoimmune condition, meaning the immune system does not work the way it should, causing inflammation (swelling) in the joints. Symptoms can include swelling, stiffness, discomfort, pain, and may cause you to have limited movement. Many people with RA might also have some symptoms that do not involve the joints like fatigue and low-grade fevers (temperature above normal, but below 100.4)." 
                             />
                        <MaternalConditionsItem title="Nausea and Vomiting of Pregnancy (NVP)" tag="Nausea and Vomiting" 
                            contentshort="NVP or morning sickness is the most common medical condition in pregnancy..."
                            contentdetails="<b>What is nausea and vomiting of pregnancy (NVP)?</b> <br/><br/>
                                NVP or �morning sickness� is the most common medical condition in pregnancy. It affects up to 80% of pregnant women. NVP symptoms can range from mild to severe. The symptoms can happen at any time during the day or at night. Symptoms can include nausea, dry heaves, retching and/or vomiting. NVP usually begins between 4-9 weeks of pregnancy, and peaks between 7-12 weeks. In most women, symptoms go away between 12-16 weeks of pregnancy. Up to 15% of women will continue to have symptoms up to 20 weeks of pregnancy or until delivery." 
                           />
                        <MaternalConditionsItem title="Diabetes and Gestational Diabetes" tag="Diabetes" 
                            contentshort="Diabetes is a medical condition in which the body either does not make enough insulin..."
                            contentdetails="<b>What is diabetes?</b> <br/><br/>
                             Diabetes is a medical condition in which the body either does not make enough insulin or cannot use insulin correctly. Insulin is a hormone in the blood that is necessary for providing our cells with energy to function. Insulin helps sugar (glucose) move from the bloodstream into the cells. When glucose cannot enter our cells, it builds up in the blood (hyperglycemia). These high sugar levels can lead to damage of organs like the eyes and kidneys, and damage blood vessels and nerves.
                             <br/><br/>
                             There are different classes of diabetes. Some people have Type 2 diabetes (once called adult onset diabetes). This means that the body does not produce enough insulin or the insulin is not able to work well. In contrast, people with Type 1 diabetes (once called juvenile-onset diabetes or insulin-dependent diabetes) have a condition where the body does not produce any insulin at all. People with Type 1 diabetes need insulin injections and close monitoring to control their blood sugar levels.<br/><br/>" 
                             />
                        <MaternalConditionsItem title="Depression" tag="Depression" 
                            contentshort="Depression is a serious medical illness. It can change how someone feels, thinks and acts. The most common
                            symptoms.."
                            contentdetails="<b>What is depression and how common is it in pregnancy?<b><br/><br/>
                                Depression is a serious medical illness. It can change how someone feels, thinks and acts. The most common
                                symptoms of depression are long-lasting and strong feelings of sadness and not being able to feel pleasure or happiness.
                                Other symptoms of depression are anxiety, irritability, difficulty concentrating, fatigue (feeling very tired), and
                                thoughts of death or self-harm. Physical symptoms of depression can include increased heart rate, loss of appetite,
                                stomach pain, and headaches.<br/><br/>
                                The chance for a woman to develop depression during her lifetime is about 10-25%. The highest risk occurs
                                during the childbearing years. Pregnancy may be a possible trigger for the development of depression in some women.
                                This may be due to changes in hormone levels during pregnancy and the stress that comes with this major life event.
                                Treatment for depression usually includes counseling/psychotherapy and/or medications" 
                         />
                    </ul>
                    <div className='maternal-conditions__btn'>
                        <Link to='/maternal-medical-conditions'>
                            <Button color='green'>View all</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default MaternalConditions;
