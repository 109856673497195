import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import { Input, Button, Checkbox, Dropdown } from 'semantic-ui-react';

const roles = [
  {
    key: 'Admin',
    text: 'Admin',
    value: 'Admin',
  },
  {
    key: 'Publisher',
    text: 'Publisher',
    value: 'Publisher',
  },
  {
    key: 'Viewer',
    text: 'Viewer',
    value: 'Viewer',
  },
  {
    key: 'Editor',
    text: 'Editor',
    value: 'Editor',
  },
];

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPassShown: false, password: 'testPass' };
    this.toggleShowPass = this.toggleShowPass.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  toggleShowPass(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      isPassShown: !prevState.isPassShown,
    }));
  }
  handleChange = (e, { value }) => this.setState({ password: value });
  render() {
    const { isPassShown, password } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Add new user</div>
          </div>
          <div className='app-content__body'>
            <div className='add-user'>
              <div className='add-user__box'>
                <div className='add-user__row'>
                  <div className='add-user__col'>
                    <div className='form-group'>
                      <lable className='form-group__label'>
                        Add profile image
                      </lable>
                      <div className='file-upload'>
                        <input
                          type='file'
                          className='file-upload__input'
                          accept='.png, .jpg, .jpeg, .svg'
                        />
                        <div className='file-upload__img'></div>
                        <div className='file-upload__text'>
                          Accepted formats png, jpg or svg
                        </div>
                        <div className='file-upload__btn'></div>
                      </div>
                    </div>
                  </div>
                  <div className='add-user__col-2'>
                    <div className='form-group'>
                      <lable className='form-group__label'>First name</lable>
                      <Input placeholder='First name' />
                    </div>
                  </div>
                  <div className='add-user__col-2'>
                    <div className='form-group'>
                      <lable className='form-group__label'>Last name</lable>
                      <Input placeholder='Last name' />
                    </div>
                  </div>
                  <div className='add-user__col'>
                    <div className='form-group'>
                      <lable className='form-group__label'>Organization</lable>
                      <Input placeholder='Organization' />
                    </div>
                  </div>
                  <div className='add-user__col'>
                    <div className='form-group'>
                      <lable className='form-group__label'>Email</lable>
                      <Input placeholder='Email' />
                    </div>
                  </div>
                  <div className='add-user__col'>
                    <div className='form-group'>
                      <lable className='form-group__label'>Password</lable>
                      <div className='add-user__password'>
                        {isPassShown && (
                          <Input
                            value={password}
                            className='add-user__pas-input'
                            onChange={this.handleChange}
                          ></Input>
                        )}
                        <Button onClick={this.toggleShowPass}>
                          {!isPassShown && 'Show'}
                          {isPassShown && 'Hide'}
                        </Button>
                        <Checkbox label='Send by email' />
                      </div>
                    </div>
                  </div>
                  <div className='add-user__col'>
                    <div className='form-group'>
                      <lable className='form-group__label'>Select role</lable>
                      <Dropdown
                        placeholder='Role'
                        fluid
                        selection
                        options={roles}
                      />
                    </div>
                    <ul className='add-user__desc'>
                      <li>
                        <b>Admin</b> - Lorem ipsum dolor sit amet consectetur
                        adipisicing elit. Suscipit, nulla?
                      </li>
                      <li>
                        <b>Publisher</b> - Lorem ipsum dolor sit amet
                        consectetur adipisicing elit. Suscipit, nulla?
                      </li>
                      <li>
                        <b>Viewer</b> - Lorem ipsum dolor sit amet consectetur
                        adipisicing elit. Suscipit, nulla?
                      </li>
                      <li>
                        <b>Editor</b> - Lorem ipsum dolor sit amet consectetur
                        adipisicing elit. Suscipit, nulla?
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='add-user__btn'>
                  <Button color='teal' className='btn-save'>
                    Save
                  </Button>
                  <Button className='btn-cancel'>Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default AddUser;
