import React from 'react';
import RecentUpdates from '../components/RecentUpdates';
import MainSearch from '../components/MainSearch';
import BaseLayout from '../layout/BaseLayout';
import MaternalConditions from '../components/MaternalConditions';
import InFocus from '../components/InFocus';

function Home() {
  return (
    <BaseLayout>
      <MainSearch />
      <RecentUpdates />
      <InFocus />
      <MaternalConditions />
    </BaseLayout>
  );
}

export default Home;
