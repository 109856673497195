import React from 'react';
import Logo from '../images/logo.svg';
import { Input, Button } from 'semantic-ui-react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

function ForgotPassword() {
  return (
    <div className='login-page'>
      <div className='login-page__bg'></div>
      <div className='login-page__header'>
        <div className='container'>
          <img src={Logo} alt=''></img>
        </div>
      </div>
      <div className='login-page__body'>
        <div className='container'>
          <div className='login-page__form'>
            <div className='login-page__ttl'>Forgot password?</div>
            <div className='login-page__sub-ttl'>
              Enter your email address to reset your password
            </div>
            <div className='login-page__group'>
              <label>Email</label>
              <Input placeholder='Enter email' />
            </div>
            <div className='login-page__btn'>
              <Button color='teal'>Reset password</Button>
            </div>
                <div className='login-page__link'>
                <a href='/login'>Back to login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
