import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import DrugListItem from '../components/DrugListItem';
import {
  Icon,
  Input,
  Button,
  Pagination,
  Dropdown,
  Modal,
} from 'semantic-ui-react';

const pages = [
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 'All',
    text: 'All',
    value: 'All',
  },
];

const substances = [
  {
    key: 'substance1',
    text: 'substance1',
    value: 'substance1',
  },
  {
    key: 'substance2',
    text: 'substance2',
    value: 'substance2',
  },

  {
    key: 'substance3',
    text: 'substance3',
    value: 'substance3',
  },
];

const brandnames = [
  {
    key: 'brandname1',
    text: 'brandname1',
    value: 'brandname1',
  },
  {
    key: 'brandname2',
    text: 'brandname2',
    value: 'brandname2',
  },
  {
    key: 'brandname3',
    text: 'brandname3',
    value: 'brandname3',
  },
];

const countries = [
  {
    key: 'country1',
    text: 'country1',
    value: 'country1',
  },
  {
    key: 'country2',
    text: 'country2',
    value: 'country2',
  },
  {
    key: 'country3',
    text: 'country3',
    value: 'country3',
  },
];

class DrugList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 10, showMobileMenu: true, modalOpen: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
  }
  handleChange = (e, { page }) => this.setState({ page });
  handleChange2 = (e, { substance }) => this.setState({ substance });
  handleChange3 = (e, { brandname }) => this.setState({ brandname });
  handleChange3 = (e, { country }) => this.setState({ country });
  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });
  render() {
    const {
      page,
      substance,
      brandname,
      country,
      showMobileMenu,
      modalOpen,
    } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Drug list</div>
            <Modal
              className='auto-modal'
              open={modalOpen}
              onClose={this.handleClose}
              trigger={
                <Button basic className='import-drug' onClick={this.handleOpen}>
                  <Icon name='upload' />
                  Import New
                </Button>
              }
            >
              <div className='import-modal'>
                <div className='import-modal__ttl'>Import overview</div>
                <div className='import-modal__desc'>
                  The following knowledge pages are automatically updated
                  because of changed drug properties.
                </div>
                <div className='import-modal__table'>
                  <div className='import-modal__head'>Title</div>
                  <ul className='import-modal__list'>
                    <li className='import-modal__item'>
                      <Icon name='file text' />
                      Paracetamol and pregnancy
                    </li>
                    <li className='import-modal__item'>
                      <Icon name='file text' />
                      Paracetamol and pregnancy
                    </li>
                    <li className='import-modal__item'>
                      <Icon name='file text' />
                      Paracetamol and pregnancy
                    </li>
                  </ul>
                </div>
                <div className='import-modal__footer'>
                  <Button color='teal' onClick={this.handleClose}>
                    Yes
                  </Button>
                  <Button basic onClick={this.handleClose}>
                    No
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
          <div className='app-content__body'>
            <div className='table-drug-list'>
              <div className='table-drug-list__filter'>
                <div className='table-drug-list__search'>
                  <div className='search-filter'>
                    <Input placeholder='Search drug'></Input>
                    <Button color='teal'>Search</Button>
                  </div>
                </div>

                <div className='table-drug-list__selects'>
                  <span>Filter by:</span>
                  <Dropdown
                    selection
                    placeholder='Substance'
                    options={substances}
                    onChange={this.handleChange2}
                    value={substance}
                  />
                  <Dropdown
                    selection
                    placeholder='Brandname'
                    options={brandnames}
                    onChange={this.handleChange3}
                    value={brandname}
                  />
                  <Dropdown
                    selection
                    placeholder='Country'
                    options={countries}
                    onChange={this.handleChange4}
                    value={country}
                  />
                </div>
              </div>
              <div className='table-drug-list__head'>
                <div className='table-drug-list__tr'>
                  <div className='table-drug-list__td table-drug-list__td--th'>
                    Nr.
                  </div>
                  <div className='table-drug-list__td table-drug-list__td--th'>
                    Substance
                    <div className='sort-icon'>
                      <Icon name='long arrow alternate up' />
                      <Icon name='long arrow alternate down' />
                    </div>
                  </div>
                  <div className='table-drug-list__td table-drug-list__td--th'>
                    Brandname
                    <div className='sort-icon'>
                      <Icon name='long arrow alternate up' />
                      <Icon name='long arrow alternate down' />
                    </div>
                  </div>
                  <div className='table-drug-list__td table-drug-list__td--th'>
                    ATC code
                    <div className='sort-icon'>
                      <Icon name='long arrow alternate up' />
                      <Icon name='long arrow alternate down' />
                    </div>
                  </div>
                  <div className='table-drug-list__td table-drug-list__td--th'>
                    Country
                    <div className='sort-icon'>
                      <Icon name='long arrow alternate up' />
                      <Icon name='long arrow alternate down' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='table-drug-list__body'>
                <DrugListItem num={1} />
                <DrugListItem num={2} />
                <DrugListItem num={3} />
                <DrugListItem num={4} />
                <DrugListItem num={5} />
                <DrugListItem num={6} />
                <DrugListItem num={7} />
                <DrugListItem num={8} />
                <DrugListItem num={9} />
                <DrugListItem num={10} />
              </div>
              <div className='table-drug-list__footer'>
                Last updated 16/04/2020
              </div>
            </div>

            <div className='table-pagination'>
              <span className='table-pagination__text'>Showing results:</span>
              <Dropdown
                selection
                options={pages}
                onChange={this.handleChange}
                value={page}
              />
              <span className='table-pagination__text'>Page</span>
              <Pagination
                activePage={1}
                boundaryRange={0}
                siblingRange={1}
                totalPages={22}
                ellipsisItem={undefined}
                firstItem={null}
                lastItem={null}
                prevItem={undefined}
                nextItem={undefined}
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default DrugList;
