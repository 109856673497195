import React from 'react';
import { Icon, Input, Button, Checkbox } from 'semantic-ui-react';

class UsersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isOpen: false,
      name: 'Sander Slingerland',
      email: 'sander.slingerland@orcion.com',
      organization: 'Organization1',
    };
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeOrganization = this.handleChangeOrganization.bind(this);
  }
  toggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }
  edit(e) {
    e.preventDefault();
    this.setState({ isEditing: true });
  }
  save(e) {
    e.preventDefault();
    this.setState({ isEditing: false });
  }
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleChangeOrganization(event) {
    this.setState({ organization: event.target.value });
  }
  render() {
    const { isEditing, isOpen, name, email, organization } = this.state;
    let langClass = 'table-users__lang';
    if (isOpen) {
      langClass = 'table-users__lang active';
    }
    return (
      <div>
        <div className='table-users__tr'>
          <div className='table-users__td'>
            {!isEditing && name}
            {isEditing && (
              <Input value={this.state.name} onChange={this.handleChangeName} />
            )}
          </div>
          <div className='table-users__td'>
            <span className='table-users__mobile-text'>Email</span>
            {!isEditing && email}
            {isEditing && (
              <Input
                value={this.state.email}
                onChange={this.handleChangeEmail}
              />
            )}
          </div>
          <div className='table-users__td'>
            <span className='table-users__mobile-text'>Organization</span>
            {!isEditing && organization}
            {isEditing && (
              <Input
                value={this.state.organization}
                onChange={this.handleChangeOrganization}
              />
            )}
          </div>
          <div className='table-users__td'>
            <span className='table-users__mobile-text'>Role</span>
            Admin
          </div>
          <div className='table-users__td'>
            <span className='table-users__mobile-text'>Access</span>
            <span className={langClass} onClick={this.toggle}>
              En, nl <span className='table-users__more'>+2 more</span>
            </span>
          </div>
          <div className='table-users__td'>
            <span className='table-users__mobile-text'>Last login</span>
            10/01/2020 12:04
          </div>
          <div className='table-users__td'>
            <div className='only-desktop'>
              {!isEditing && (
                <div>
                  <Button icon onClick={this.edit}>
                    <Icon color='teal' name='pencil' />
                  </Button>
                  <Button icon>
                    <Icon color='teal' name='key' />
                  </Button>
                  <Button icon>
                    <Icon color='teal' name='trash alternate outline' />
                  </Button>
                </div>
              )}
              {isEditing && (
                <div>
                  <button
                    className='table-medical-conditions__cancel'
                    onClick={this.save}
                  >
                    Cancel
                  </button>
                  <button
                    className='table-medical-conditions__save'
                    onClick={this.save}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <div>
            <div className='table-users__tr table-users__tr--additional'>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'>
                <Checkbox label='En' defaultChecked></Checkbox>
              </div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
            </div>
            <div className='table-users__tr table-users__tr--additional'>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'>
                <Checkbox label='Nl' defaultChecked></Checkbox>
              </div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
            </div>
            <div className='table-users__tr table-users__tr--additional'>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'>
                <Checkbox label='Be' defaultChecked></Checkbox>
              </div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
            </div>
            <div className='table-users__tr table-users__tr--additional'>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
              <div className='table-users__td'>
                <Checkbox label='Ge' defaultChecked></Checkbox>
              </div>
              <div className='table-users__td'></div>
              <div className='table-users__td'></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UsersRow;
