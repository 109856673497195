import React from 'react';
import InFocusItem from './InFocusItem';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import BgImage from '../images/img-in-focus-2.jpg';

function InFocus() {
  return (
    <section className='in-focus'>
      <div className='container'>
        <div className='in-focus__ttl'>In Focus</div>
        <div className='in-focus__row'>
          <div className='in-focus__left'>
            <Link to={{
                pathname: '/InFocusDetail', state: {
                    title: "COVID-19 and pregnancy",
                    details: "Pregnant women are not currently believed to be more at risk of contracting COVID-19[3]. The current evidence relating to COVID-19 infection during pregnancy does not suggest that maternal symptomatology is any more severe than that of the general population.[1,2] <br/><br/> Due to the limited data available, it is currently unknown whether infection with the virus itself exerts direct teratogenic or fetotoxic effects.[2] The limited preliminary data currently available do not suggest higher than expected rates of fetal loss or growth restriction.[1, 3] However evidence relating to pregnancy outcomes following infection in early pregnancy is currently highly limited.[1] <br/><br/> Although higher than expected rates of preterm delivery have been described, [1] it is unclear what proportion of these have been iatrogenic due to deterioration in the maternal condition.[3] <br/><br/>Although a small number of neonates have tested positive for the virus, [1] it is currently unclear if this has occurred as a result of transplacental infection, or whether the infants acquired the infection during or shortly following delivery."
                }
            }} className='focus-news focus-news--big'>
            <div
                className='focus-news__img'
                style={{ backgroundImage: `url(${BgImage})` }}
            ></div>
            <div className='focus-news__info'>
                <div className='focus-news__ttl'>
                    COVID-19 and pregnancy
                </div>
                <p className='focus-news__text'>
                    Pregnant women are not currently believed to be more at risk of contracting COVID-19…
                </p>
                <div className='focus-news__footer'>
                <span className='focus-news__link'>Read more</span>
                <div className='focus-news__date'>28 June</div>
                </div>
            </div>
            </Link>
          </div>
          <div className='in-focus__right'>
            <ul className='in-focus__list'>
                          <InFocusItem title="WHO recommends dolutegravir as preferred HIV treatment option in all populations" short="Based on new evidence assessing benefits and risks, the WHO recommends..." details="Based on new evidence assessing benefits and risks, the WHO recommends the use of the HIV drug dolutegravir (DTG) as the preferred first-line and second-line treatment for all populations, including pregnant women and those of childbearing potential.  <br/><br/>
Initial studies had highlighted a possible link between DTG and neural tube defects (birth defects of the brain and spinal cord that cause conditions such as spina bifida) in infants born to women using the drug at the time of conception. New data from two large clinical trials comparing the efficacy and safety of DTG and EFV in Africa have now expanded the evidence base. The risks of neural tube defects are significantly lower than what the initial studies may have suggested.<br/><br/>
DTG is a drug that is more effective, easier to take and has fewer side effects than alternative drugs that are currently used. DTG also has a high genetic barrier to developing drug resistance, which is important given the rising trend of resistance to EFV and nevirapine-based regimens. <br/><br/>
As for any medications, informed choice is important. Every treatment decision needs to be based on an informed discussion with the health provider weighing the benefits and potential risks. WHO also stresses the importance of providing information and options to help women make an informed choice. " />
                          <InFocusItem title="Pain medication during pregnancy" short="Pain in pregnancy is common. There are no specific guidelines on the treatment..." details="Pain in pregnancy is common. There are no specific guidelines on the treatment of pain in pregnancy. If a painkiller (sometimes called an analgesic) is required, the choice will depend on the type and severity of pain and the stage of pregnancy. A doctor may assess your pain on a ‘pain scale’ to help decide the most suitable treatment. Pregnant women with long-term conditions that are associated with pain may be cared for by a specialist.<br/><br/>
When deciding whether to use a painkiller during pregnancy it is important to weigh up how necessary the treatment is against any possible risks to you or your baby, some of which might depend on how many weeks pregnant you are. <br/><br/>
<b>Paracetamol</b> is regarded as the medicine of choice for mild-to-moderate pain in pregnancy. It has a good safety profile based on a large number of pregnant women studied, and although possible links with autism and ADHD in children who were exposed in the womb have been suggested, these findings are considered by some experts to be unconvincing and remain unproven. For more information please see the bump leaflet on Paracetamol use in pregnancy. <br/><br/>

<b>NSAIDs</b> such as ibuprofen, diclofenac and naproxen might be prescribed by a doctor for use in the first and second trimesters. NSAIDs should not be used after 30 weeks of pregnancy as they may affect the wellbeing of the baby. For more information please see the bump leaflets on Ibuprofen , Diclofenac and Naproxen.<br/><br/>

<b>Codeine</b> has a number of side effects, and use in pregnancy, especially in the weeks leading up to delivery, can result in withdrawal symptoms in the baby after birth. It may therefore only be offered by your doctor if the previous treatment options have not worked, are not suitable for you, or could not be used. Further information on the fetal effects of use in pregnancy can be found in the bump leaflet on Codeine.
" />
                          <InFocusItem title="Ondansetron use during pregnancy" short="Ondansetron is a medicine used for the treatment of vomiting and nausea..." details="Ondansetron is a medicine used for the treatment of vomiting and nausea. The European Medicines Agency says that ondansetron containing medicines should not be used in the first trimester of pregnancy because of an increased risk of orofacial malformations. The increased risk is three additional cases of orofacial cleft per 10,000 women using ondansetron during first trimester.  <br/><br/>
The European Network of Teratology Information Services, ENTIS, does not support the recommendations made by the EMA PRAC. ENTIS believes these would result in less effective control of maternal nausea and vomiting, leading to an increased maternal morbidity and hospitalisation, and an increased risk of termination of wanted pregnancies. <br/><br/>
Additionally, they would like to minimise the inevitable and disproportionate concern generated by the proposed changes to product literature for women who have taken ondansetron in the first trimester. In view of the most recently published data, ENTIS recommend that ondansetron should be reserved as a second line agent for the treatment of nausea and vomiting in pregnancy (as currently recommended by the UK RCOG Green-top Guideline)[7] . Patients must be adequately counselled regarding the benefits of ondansetron together with the small increase in risk of orofacial cleft which may exist. Ondansetron should still be considered an option for patients with severe vomiting in pregnancy in whom first line treatments have failed.
" />
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InFocus;
