import React from 'react';
import { Input, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      value: this.props.value || '',
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  render() {
    let color = 'teal';
    if (this.props.buttonColor === 'darkBlue') {
      color = 'blue';
    }
    return (
      <div className='search-bar'>
        <Icon name='search' />
        <Input
          placeholder='Search...'
          value={this.state.value}
          onChange={this.handleChange}
            />
            <Link to={{
                pathname: '/Search-result', state: {
                    keyword: this.state.value
                }
            }}>
                <Button style={{ backgroundColor: "#A2D8F4" }}>Search</Button>
            </Link>
      </div>
    );
  }
}

export default SearchBar;
