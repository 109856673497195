import React from 'react';
import RecentUpdatesItem from './RecentUpdatesItem';

function RecentUpdates() {
  return (
    <section className='recent-updates'>
      <div className='container'>
        <div className='recent-updates__box'>
          <div className='recent-updates__ttl'>Recent knowledge updates</div>
          <ul className='recent-updates__list'>
                      <RecentUpdatesItem title="Azithromycin" short="Azithromycin can be used in pregnancy if there is an infection which could affect the health..." />
             <RecentUpdatesItem title="Tocilizumab" short="In some patients, the benefits of using tocilizumab in pregnancy may outweigh the potential risks..." />
            <RecentUpdatesItem title="Rheumatoid Arthritis" short="Rheumatoid arthritis (RA) is a kind of arthritis that mainly affects your joints. It is an autoimmune condition..." />
          </ul>
        </div>
      </div>
    </section>
  );
}

export default RecentUpdates;
