import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import UsersRow from '../components/UsersRow';
import { Link } from 'react-router-dom';
import { Dropdown, Pagination, Input, Button, Icon } from 'semantic-ui-react';

const pages = [
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 'All',
    text: 'All',
    value: 'All',
  },
];

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: '', page: 10 };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (e, { page }) => this.setState({ page });
  render() {
    const { page } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Users</div>
            <Button
              color='teal'
              as={Link}
              to='/users/add'
              className='add-in-focus'
            >
              <Icon name='plus' />
              New User
            </Button>
          </div>
          <div className='app-content__body'>
            <div className='table-users'>
              <div className='table-users__filter'>
                <div className='table-users__search'>
                  <div className='search-filter'>
                    <Input placeholder='Search user'></Input>
                    <Button color='teal'>Search</Button>
                  </div>
                </div>
              </div>
              <div className='table-users__head'>
                <div className='table-users__tr'>
                  <div className='table-users__td table-users__td--th'>
                    Name
                  </div>
                  <div className='table-users__td table-users__td--th'>
                    Email
                  </div>
                  <div className='table-users__td table-users__td--th'>
                    Organization
                  </div>
                  <div className='table-users__td table-users__td--th'>
                    Role
                  </div>
                  <div className='table-users__td table-users__td--th'>
                    Access to translations
                  </div>
                  <div className='table-users__td table-users__td--th'>
                    Last login
                  </div>
                  <div className='table-users__td table-users__td--th'></div>
                </div>
              </div>
              <div className='table-users__body'>
                <UsersRow />
                <UsersRow />
                <UsersRow />
                <UsersRow />
                <UsersRow />
                <UsersRow />
                <UsersRow />
              </div>
            </div>

            <div className='table-pagination'>
              <span className='table-pagination__text'>Showing results:</span>
              <Dropdown
                selection
                options={pages}
                onChange={this.handleChange}
                value={page}
              />
              <span className='table-pagination__text'>Page</span>
              <Pagination
                activePage={1}
                boundaryRange={0}
                siblingRange={1}
                totalPages={22}
                ellipsisItem={undefined}
                firstItem={null}
                lastItem={null}
                prevItem={undefined}
                nextItem={undefined}
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default Users;
