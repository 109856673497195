import React from 'react';
import { Dropdown } from 'semantic-ui-react';

class ChooseTranslation extends React.Component {
  render() {
    return (
      <div className='choose-translation'>
        <div className='choose-translation__ttl'>Choose translation:</div>
        <div className='choose-translation__current-lang'>Dutch</div>
        <div className='choose-translation__select'>
          <Dropdown text='More'>
            <Dropdown.Menu direction='left'>
              <Dropdown.Item text='English  ' />
              <Dropdown.Item text='Franch' />
              <Dropdown.Item text='Netherland' />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default ChooseTranslation;
