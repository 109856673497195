import React from 'react';
import Home from './pages/Home';
import SearchResult from './pages/SearchResult';
import DetailPage from './pages/DetailPage';
import Api from './pages/Api';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Knowledge from './pages/Knowledge';
import ApiKey from './pages/ApiKey';
import MedicalConditions from './pages/MedicalConditions';
import AddUser from './pages/AddUser';
import InFocus from './pages/InFocus';
import CreateInFocus from './pages/CreateInFocus';
import DrugList from './pages/DrugList';
import Translations from './pages/Translations';
import Users from './pages/Users';
import Website from './pages/Website';
import PreviewKnowledge from './pages/PreviewKnowledge';
import EditKnowledgePage from './pages/EditKnowledgePage';
import MedicalConditionsPub from './pages/MedicalConditionsPub';
import MedicalConditionsDetail from './pages/MedicalConditionsDetail';
import AboutUs from './pages/AboutUs';
import HowWeWork from './pages/HowWeWork';
import CreateNewPage from './pages/CreateNewPage';
import InFocusDetail from './pages/InFocusDetail';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'rc-datepicker/lib/style.css';

export default function App() {
  return (
    <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path='/search-result' component={SearchResult}></Route>
          <Route path='/detail-page' component={DetailPage}></Route>
          <Route path='/api' component={Api}></Route>
          <Route path='/login' component={Login}></Route>
          <Route path='/MedicalConditionsDetail' component={MedicalConditionsDetail}></Route>
          <Route path='/forgot-password' component={ForgotPassword}></Route>
          <Route path='/admin/knowledge/preview' component={PreviewKnowledge}></Route>
          <Route path='/admin/knowledge/edit' component={EditKnowledgePage}></Route>
          <Route path='/admin/knowledge' component={Knowledge}></Route>
          <Route path='/admin/api-key' component={ApiKey}></Route>
          <Route path='/medical-conditions' component={MedicalConditions}></Route>
          <Route path='/maternal-medical-conditions' component={MedicalConditionsPub}></Route>
          <Route path='/users/add' component={AddUser}></Route>
          <Route path='/users' component={Users}></Route>
          <Route path='/in-focus-pages' component={InFocus}></Route>
          <Route path='/in-focus-pages-create' component={CreateInFocus}></Route>
          <Route path='/drug-list' component={DrugList}></Route>
          <Route path='/translations' component={Translations}></Route>
          <Route path='/InFocusDetail' component={InFocusDetail}></Route>
          <Route path='/admin/website' component={Website}></Route>
          <Route path='/about-us' component={AboutUs}></Route>
          <Route path='/admin/website-add' component={CreateNewPage}></Route>
          <Route path='/how-we-work' component={HowWeWork}></Route>
          <Route path='/' component={Home}></Route>
        </Switch>
    </Router>
  );
}
