import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import SearchBar from '../components/SearchBar';
import SearchResultItem from '../components/SearchResultItem';
import { Pagination, Breadcrumb } from 'semantic-ui-react';

class SearchResult extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const sections = [
            { key: 'Home', content: 'Home', link: true },
            { key: 'Search', content: 'Search result', active: true },
        ];

        return (
            <BaseLayout headerWithShadow={true}>
                <section className='breadcrumbs'>
                    <div className='container'>
                        <Breadcrumb icon='right angle' sections={sections} />
                    </div>
                </section>
                <section className='search-result'>
                    <div className='container'>
                        <div className='search-result__box'>
                            <div className='search-result__ttl'>Search results </div>
                            <div className='search-result__search-bar'>
                                <SearchBar value={this.props.location.state.keyword} />
                            </div>
                            <ul>
                                <SearchResultItem title="Azithromycin" keyword={this.props.location.state.keyword} contentshort="Azithromycin can be used in pregnancy if there is an infection which could affect the health..." />
                                <SearchResultItem title="Tocilizumab" keyword={this.props.location.state.keyword} contentshort="In some patients, the benefits of using tocilizumab in pregnancy may outweigh the potential risks..." />
                                <SearchResultItem title="Rheumatoid Arthritis" keyword={this.props.location.state.keyword} contentshort="Rheumatoid arthritis (RA) is a kind of arthritis that mainly affects your joints. It is an autoimmune condition..." />
                                <SearchResultItem title="Nausea and Vomiting of Pregnancy (NVP)" keyword={this.props.location.state.keyword} contentshort="NVP or morning sickness is the most common medical condition in pregnancy..." />
                                <SearchResultItem title="Diabetes and Gestational Diabetes" keyword={this.props.location.state.keyword} contentshort="Diabetes is a medical condition in which the body either does not make enough insulin..." />
                                <SearchResultItem title="Depression" keyword={this.props.location.state.keyword} contentshort="Depression is a serious medical illness. It can change how someone feels, thinks and acts. The most common symptoms.." />
                            </ul>
                            <div className='search-result__pagination'>
                                <Pagination
                                    activePage={1}
                                    boundaryRange={0}
                                    siblingRange={1}
                                    totalPages={22}
                                    ellipsisItem={undefined}
                                    firstItem={null}
                                    lastItem={null}
                                    prevItem={undefined}
                                    nextItem={undefined}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </BaseLayout>
        );
    }
}

export default SearchResult;
