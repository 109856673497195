import React from 'react';
import {
  Dropdown,
  Form,
  TextArea,
  Button,
  Checkbox,
  Icon,
  Input,
} from 'semantic-ui-react';
import Ava from '../images/ico-ava.svg';
import icoColoboration from '../images/ico-coloboration.svg';
import icoColoboration1 from '../images/ico-coloboration-1.svg';
import icoColoboration2 from '../images/ico-coloboration-2.svg';

const keywords = [];
const atc = [];
const shomed = [];
const array = [];
const links = [];

class EditKnowledgePageTabDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords,
      atc,
      shomed,
      array,
      links,
      open1: true,
      open2: false,
      keywordsValue: '',
      keywordsValue2: '',
      keywordsValue4: '',
      keywordsValue5: '',
      value2: '',
      pregnancyText:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip. Duis aute irure dolor in reprehenderit in voluptate velit esse',
      pregnancyDetail:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
    };
    this.handleAddKeyword = this.handleAddKeyword.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleAddKeyword2 = this.handleAddKeyword2.bind(this);
    this.handleChangeKeyword2 = this.handleChangeKeyword2.bind(this);
    this.handleAddKeyword3 = this.handleAddKeyword3.bind(this);
    this.handleChangeKeyword3 = this.handleChangeKeyword3.bind(this);
    this.handleAddKeyword4 = this.handleAddKeyword4.bind(this);
    this.handleChangeKeyword4 = this.handleChangeKeyword4.bind(this);
    this.handleAddKeyword5 = this.handleAddKeyword5.bind(this);
    this.handleChangeKeyword5 = this.handleChangeKeyword5.bind(this);
    this.handleChangePregnancyText = this.handleChangePregnancyText.bind(this);
    this.handleChangePregnancyDetail = this.handleChangePregnancyDetail.bind(
      this
    );
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }
  handleAddKeyword(e, { value }) {
    this.setState((prevState) => ({
      keywords: [{ text: value, value }, ...prevState.keywords],
    }));
  }

  handleChangeKeyword(e, { value }) {
    this.setState({ keywordsValue: value });
  }

  handleAddKeyword2(e, { value }) {
    this.setState((prevState) => ({
      atc: [{ text: value, value }, ...prevState.atc],
    }));
  }

  handleChangeKeyword2(e, { value }) {
    this.setState({ value2: value });
  }

  handleAddKeyword3(e, { value }) {
    this.setState((prevState) => ({
      array: [{ text: value, value }, ...prevState.array],
    }));
  }

  handleChangeKeyword3(e, { value }) {
    this.setState({ keywordsValue2: value });
  }

  handleAddKeyword4(e, { value }) {
    this.setState((prevState) => ({
      shomed: [{ text: value, value }, ...prevState.shomed],
    }));
  }

  handleChangeKeyword4(e, { value }) {
    this.setState({ keywordsValue4: value });
  }

  handleAddKeyword5(e, { value }) {
    this.setState((prevState) => ({
      links: [{ text: value, value }, ...prevState.links],
    }));
  }

  handleChangeKeyword5(e, { value }) {
    this.setState({ keywordsValue5: value });
  }

  handleChangePregnancyText(event) {
    this.setState({ pregnancyText: event.target.value });
  }
  handleChangePregnancyDetail(event) {
    this.setState({ pregnancyDetail: event.target.value });
  }
  toggle1(e) {
    e.preventDefault();
    this.setState((state) => ({
      open1: !state.open1,
    }));
  }
  toggle2(e) {
    e.preventDefault();
    this.setState((state) => ({
      open2: !state.open2,
    }));
  }

  render() {
    const {
      keywordsValue,
      keywords,
      value2,
      atc,
      shomed,
      array,
      keywordsValue2,
      keywordsValue4,
      pregnancyText,
      pregnancyDetail,
      open1,
      open2,
      links,
      keywordsValue5,
    } = this.state;

    let style1 = 'edit-knowledge-block__show';
    if (open1) {
      style1 = 'edit-knowledge-block__show active';
    }

    let style2 = 'edit-knowledge-block__show';
    if (open2) {
      style2 = 'edit-knowledge-block__show active';
    }
    return (
      <div className='edit-tab-details'>
        <div className='edit-tab-details__left'>
          <div className='edit-knowledge-block'>
            <div className='edit-knowledge-block__header'>
              <div className='edit-knowledge-block__ttl'>Pregnancy</div>
              <div className={style1}>
                <button onClick={this.toggle1}>Show</button>
              </div>
            </div>
            {open1 && (
              <div className='edit-knowledge-block__body'>
                <div className='edit-knowledge-block__elem'>
                  <Form>
                    <TextArea
                      placeholder=''
                      value={pregnancyText}
                      onChange={this.handleChangePregnancyText}
                    />
                  </Form>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='edit-knowledge-block__sub-ttl'>Details</div>
                  <Form>
                    <TextArea
                      placeholder=''
                      value={pregnancyDetail}
                      onChange={this.handleChangePregnancyDetail}
                    />
                  </Form>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='edit-knowledge-block__sub-ttl'>
                    References
                  </div>
                  <div className='references-list'>
                    <div className='references-list__item'>
                      <Checkbox></Checkbox>
                      <div className='references-list__input'>
                        <Input fluid></Input>
                      </div>
                      <div className='references-list__id'>
                        or
                        <Input placeholder='PubMedID'></Input>
                        <Button>
                          <Icon color='teal' name='plus circle' />
                        </Button>
                      </div>
                    </div>
                    <div className='references-list__item'>
                      <div className='references-list__name'>
                        <Checkbox label='1. Hoppert M. Microscopic techniques in biotechnology. Weinheim (Germany): Wiley-VCH; 2003.' />
                      </div>
                      <div className='references-list__action'>
                        <Button icon>
                          <Icon color='teal' name='long arrow alternate up' />
                        </Button>
                        <Button icon>
                          <Icon color='teal' name='long arrow alternate down' />
                        </Button>
                        <Button icon>
                          <Icon color='teal' name='trash alternate outline' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='edit-knowledge-block__sub-ttl'>
                    Internal comments
                  </div>
                  <div className='edit-knowledge-block__comment'>
                    <Form>
                      <TextArea placeholder='' />
                    </Form>
                    <Button basic>Save</Button>
                  </div>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='comment-box'>
                    <div className='comment-box__header'>
                      <div className='comment-box__ava'>
                        <img src={Ava} alt='' />
                      </div>
                      <div className='comment-box__name'>Hattie Guzman</div>
                      <div className='comment-box__date'>10/10/2019 10:12</div>
                    </div>
                    <div className='comment-box__body'>
                      <div className='comment-box__quote'>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip.
                      </div>
                      <div className='comment-box__text'>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip. Duis aute irure dolor
                        in reprehenderit in voluptate velit esse
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='edit-knowledge-block'>
            <div className='edit-knowledge-block__header'>
              <div className='edit-knowledge-block__ttl'>Lactation</div>
              <div className={style2}>
                <button onClick={this.toggle2}>Show</button>
              </div>
            </div>
            {open2 && (
              <div className='edit-knowledge-block__body'>
                <div className='edit-knowledge-block__elem'>
                  <Form>
                    <TextArea
                      placeholder=''
                      value={pregnancyText}
                      onChange={this.handleChangePregnancyText}
                    />
                  </Form>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='edit-knowledge-block__sub-ttl'>Details</div>
                  <Form>
                    <TextArea
                      placeholder=''
                      value={pregnancyDetail}
                      onChange={this.handleChangePregnancyDetail}
                    />
                  </Form>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='edit-knowledge-block__sub-ttl'>
                    References
                  </div>
                  <div className='references-list'>
                    <div className='references-list__item'>
                      <Checkbox></Checkbox>
                      <div className='references-list__input'>
                        <Input fluid></Input>
                      </div>
                      <div className='references-list__id'>
                        or
                        <Input placeholder='PubMedID'></Input>
                        <Button>
                          <Icon color='teal' name='plus circle' />
                        </Button>
                      </div>
                    </div>
                    <div className='references-list__item'>
                      <div className='references-list__name'>
                        <Checkbox label='1. Hoppert M. Microscopic techniques in biotechnology. Weinheim (Germany): Wiley-VCH; 2003.' />
                      </div>
                      <div className='references-list__action'>
                        <Button icon>
                          <Icon color='teal' name='long arrow alternate up' />
                        </Button>
                        <Button icon>
                          <Icon color='teal' name='long arrow alternate down' />
                        </Button>
                        <Button icon>
                          <Icon color='teal' name='trash alternate outline' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='edit-knowledge-block__sub-ttl'>
                    Internal comments
                  </div>
                  <div className='edit-knowledge-block__comment'>
                    <Form>
                      <TextArea placeholder='' />
                    </Form>
                    <Button basic>Save</Button>
                  </div>
                </div>
                <div className='edit-knowledge-block__elem'>
                  <div className='comment-box'>
                    <div className='comment-box__header'>
                      <div className='comment-box__ava'>
                        <img src={Ava} alt='' />
                      </div>
                      <div className='comment-box__name'>Hattie Guzman</div>
                      <div className='comment-box__date'>10/10/2019 10:12</div>
                    </div>
                    <div className='comment-box__body'>
                      <div className='comment-box__quote'>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip.
                      </div>
                      <div className='comment-box__text'>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip. Duis aute irure dolor
                        in reprehenderit in voluptate velit esse
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='edit-tab-details__right'>
          <div className='details-setting'>
            <div className='details-setting__item'>
              <div className='details-setting__ttl'>Drugs</div>
              <div className='drug-box'>
                <div className='drug-box__item'>
                  <Dropdown
                    className='multiple-dropdown'
                    options={array}
                    placeholder='Add'
                    search
                    selection
                    fluid
                    allowAdditions
                    multiple
                    value={keywordsValue2}
                    onAddItem={this.handleAddKeyword3}
                    onChange={this.handleChangeKeyword3}
                  />
                </div>
                <div className='drug-box__item'>
                  <div className='drug-box__ttl'>ATC</div>
                  <Dropdown
                    className='multiple-dropdown'
                    options={atc}
                    placeholder='Add ATC'
                    search
                    selection
                    fluid
                    allowAdditions
                    multiple
                    value={value2}
                    onAddItem={this.handleAddKeyword2}
                    onChange={this.handleChangeKeyword2}
                  />
                </div>
                <div className='drug-box__item'>
                  <div className='drug-box__ttl'>SHOMED</div>
                  <Dropdown
                    className='multiple-dropdown'
                    options={shomed}
                    placeholder='Add Shomed'
                    search
                    selection
                    fluid
                    allowAdditions
                    multiple
                    value={keywordsValue4}
                    onAddItem={this.handleAddKeyword4}
                    onChange={this.handleChangeKeyword4}
                  />
                </div>
              </div>
            </div>
            <div className='details-setting__item'>
              <div className='details-setting__ttl'>Keywords</div>
              <Dropdown
                className='multiple-dropdown'
                options={keywords}
                placeholder='Add Keywords'
                search
                selection
                fluid
                allowAdditions
                multiple
                value={keywordsValue}
                onAddItem={this.handleAddKeyword}
                onChange={this.handleChangeKeyword}
              />
            </div>
            <div className='details-setting__item'>
              <div className='details-setting__ttl'>
                Link to other knowledge pages
              </div>
              <Dropdown
                className='multiple-dropdown'
                options={links}
                placeholder='Add page'
                search
                selection
                fluid
                allowAdditions
                multiple
                value={keywordsValue5}
                onAddItem={this.handleAddKeyword5}
                onChange={this.handleChangeKeyword5}
              />
            </div>
            <div className='details-setting__item'>
              <div className='details-setting__ttl'>
                Collaboration <img src={icoColoboration} alt='' />
              </div>
              <div className='details-setting__collaboration'>
                <a href='/'>
                  <img src={icoColoboration1} alt='' />
                </a>
                <a href='/'>
                  <img src={icoColoboration2} alt='' />
                </a>
                <a href='/'>Edit</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditKnowledgePageTabDetails;
