import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import TextEditor from '../components/TextEditor';
import { Input, Button, Dropdown } from 'semantic-ui-react';

const pages = [
  {
    key: 'page1',
    text: 'Paracetamol and pregnancy',
    value: 'Paracetamol and pregnancy',
  },
  { key: 'page2', text: 'Paracetamol', value: 'Paracetamol' },
  { key: 'page3', text: 'Pregnancy', value: 'Pregnancy' },
];

class CreateInFocus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { title: '', pages };
  }
  handleChangePage = (e, { value }) => this.setState({ page: value });
  handleChangeTitle = (e, { value }) => this.setState({ title: value });
  render() {
    const { title, page } = this.state;

    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Create In focus page</div>
          </div>
          <div className='app-content__body'>
            <div className='create-infocus'>
              <div className='create-infocus__header'>Page info</div>
              <div className='create-infocus__body'>
                <div className='create-infocus__row'>
                  <div className='create-infocus__col'>
                    <label>Title</label>
                    <Input
                      placeholder='Title'
                      value={title}
                      onChange={this.handleChangeTitle}
                    />
                  </div>
                  <div className='create-infocus__col'>
                    <label>Page</label>
                    <Dropdown
                      options={this.state.pages}
                      placeholder='Choose'
                      search
                      fluid
                      selection
                      value={page}
                      onChange={this.handleChangePage}
                    />
                  </div>
                  <div className='create-infocus__col'>
                    <label>Page cover</label>
                    <div className='file-upload'>
                      <input
                        type='file'
                        className='file-upload__input'
                        accept='.png, .jpg, .jpeg, .svg'
                      />
                      <div className='file-upload__text'>
                        Accepted formats png, jpg or svg
                      </div>
                      <div className='file-upload__btn'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='create-infocus'>
              <div className='create-infocus__header'>Page content</div>
              <div className='create-infocus__body'>
                <div className='create-infocus__editor'>
                  <TextEditor />
                </div>
              </div>
            </div>
            <div className='create-infocus__footer'>
              <Button basic>Cancel</Button>
              <Button color='teal'>Save Changes</Button>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default CreateInFocus;
