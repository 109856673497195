import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import MedicalConditionsItem from '../components/MedicalConditionsItem';
import { Button, Icon, Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'Headache', text: 'Headache', value: 'Headache' },
  { key: 'Depression', text: 'Depression', value: 'Depression' },
];

const pages = [
  {
    key: 'page1',
    text: 'Paracetamol and pregnancy',
    value: 'Paracetamol and pregnancy',
  },
  { key: 'page2', text: 'Paracetamol', value: 'Paracetamol' },
  { key: 'page3', text: 'Pregnancy', value: 'Pregnancy' },
];
class MedicalConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options,
      pages,
      page: 'Paracetamol and pregnancy',
      open: false,
    };
    this.wrapperRef = React.createRef();
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.handleClose();
    }
  }
  handleAddition(e, { value }) {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  }
  handleChange(e, { value }) {
    this.setState({ currentValue: value });
  }
  handleChangePage(e, { value }) {
    this.setState({ page: value });
  }
  handleClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }
  handleClose() {
    this.setState({ open: false });
  }
  render() {
    const { page, currentValue, open } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Maternal medical conditions</div>
            <div className='add-medical' ref={this.wrapperRef}>
              <Button color='teal' onClick={this.handleClick}>
                <Icon name='plus'></Icon> Add new
              </Button>
              {open && (
                <div className='add-medical-conditions'>
                  <div className='add-medical-conditions__ttl'>
                    Add new medical condition
                  </div>
                  <div className='add-medical-conditions__body'>
                    <Dropdown
                      options={this.state.pages}
                      placeholder='Choose'
                      search
                      fluid
                      selection
                      value={page}
                      onChange={this.handleChangePage}
                    />
                    <Dropdown
                      className='indication'
                      options={this.state.options}
                      placeholder='Choose'
                      search
                      fluid
                      selection
                      allowAdditions
                      value={currentValue}
                      onAddItem={this.handleAddition}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='add-medical-conditions__footer'>
                    <Button basic onClick={this.handleClose}>
                      Cancel
                    </Button>
                    <Button color='teal' onClick={this.handleClose}>
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='app-content__body'>
            <div className='table-medical-conditions'>
              <div className='table-medical-conditions__head'>
                <div className='table-medical-conditions__tr'>
                  <div className='table-medical-conditions__td table-medical-conditions__td--th'>
                    Nr.
                  </div>
                  <div className='table-medical-conditions__td table-medical-conditions__td--th'>
                    Knowledge page
                  </div>
                  <div className='table-medical-conditions__td table-medical-conditions__td--th'>
                    Indication
                  </div>
                  <div className='table-medical-conditions__td table-medical-conditions__td--th'></div>
                </div>
              </div>
              <div className='table-medical-conditions__body'>
                <MedicalConditionsItem pinned />
                <MedicalConditionsItem pinned />
                <MedicalConditionsItem pinned />
                <MedicalConditionsItem pinned />
                <MedicalConditionsItem />
                <MedicalConditionsItem />
                <MedicalConditionsItem />
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default MedicalConditions;
