import React from 'react';
import TabPagesRow from '../components/TabPagesRow';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Icon, Dropdown, Pagination, Button } from 'semantic-ui-react';

const pages = [
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 'All',
    text: 'All',
    value: 'All',
  },
];

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 10 };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (e, { page }) => this.setState({ page });
  render() {
    const { page } = this.state;
    return (
      <div>
        <div className='website-tabs__content'>
          <div className='table-pages'>
            <div className='table-pages__header'>
            <div className='table-pages__ttl'>Pages</div>
                <Link to='/admin/website-add'>
                    <Button color='teal'>
                        <Icon name='plus' /> Create New Page
                        </Button>
                </Link>
            </div>
            <div className='table-pages__head'>
              <div className='table-pages__tr'>
                <div className='table-pages__td table-pages__td--th'>Nr.</div>
                <div className='table-pages__td table-pages__td--th'>
                  Title{' '}
                  <div className='sort-icon'>
                    <Icon name='long arrow alternate up' />
                    <Icon name='long arrow alternate down' />
                  </div>
                </div>
                <div className='table-pages__td table-pages__td--th'>Link</div>
                <div className='table-pages__td table-pages__td--th'></div>
              </div>
            </div>
            <div className='table-pages__body'>
              <TabPagesRow num={1} />
              <TabPagesRow num={2} />
              <TabPagesRow num={3} />
              <TabPagesRow num={4} />
              <TabPagesRow num={5} />
              <TabPagesRow num={6} />
              <TabPagesRow num={7} />
            </div>
          </div>
        </div>
        <div className='website-tabs__footer'>
          <div className='table-pagination'>
            <span className='table-pagination__text'>Showing results:</span>
            <Dropdown
              selection
              options={pages}
              onChange={this.handleChange}
              value={page}
            />
            <span className='table-pagination__text'>Page</span>
            <Pagination
              activePage={1}
              boundaryRange={0}
              siblingRange={1}
              totalPages={22}
              ellipsisItem={undefined}
              firstItem={null}
              lastItem={null}
              prevItem={undefined}
              nextItem={undefined}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
