import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

function RecentUpdatesItem(props) {
  return (
      <li className='recent-updates__item'>
          <Link to={{ pathname: '/Detail-page', state: { title: props.title } }} className='recent-news'>
          <div className='recent-news__ttl'>
                  {props.title}
          </div>
          <p className='recent-news__text'>
                  {props.short}
          </p>
          <div className='recent-news__footer'>
            <span className='recent-news__link'>Read more</span>
            <div className='recent-news__date'>28 June</div>
          </div>
        </Link>
    </li>
  );
}

export default RecentUpdatesItem;
