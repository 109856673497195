import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import TranslationsRow from '../components/TranslationsRow';
import { Icon, Button } from 'semantic-ui-react';

class Translations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Translations</div>
            <Button color='teal'>
              <Icon name='plus' /> Add Translation
            </Button>
          </div>
          <div className='app-content__body'>
            <div className='table-translations'>
              <div className='table-translations__head'>
                <div className='table-translations__tr'>
                  <div className='table-translations__td table-translations__td--th'>
                    Name
                  </div>
                  <div className='table-translations__td table-translations__td--th'>
                    Email
                  </div>
                  <div className='table-translations__td table-translations__td--th'></div>
                </div>
              </div>
              <div className='table-translations__body'>
                <TranslationsRow />
                <TranslationsRow />
                <TranslationsRow />
                <TranslationsRow />
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default Translations;
