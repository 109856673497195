import React from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';

class ApiKeyItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editing: false, value: 'id' };
    this.handleChange = this.handleChange.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  edit(e) {
    e.preventDefault();
    this.setState({ editing: true });
  }
  save(e) {
    e.preventDefault();
    this.setState({ editing: false });
  }
  render() {
    const { value, editing } = this.state;
    let trClass = 'table-api-key__tr';
    if (editing) {
      trClass = 'table-api-key__tr active';
    }
    return (
      <div className={trClass}>
        <div className='table-api-key__td'>
          {!editing && value}
          {editing && <Input value={value} onChange={this.handleChange} />}
          <div className='only-mobile'>
            {!editing && (
              <div>
                <Button icon onClick={this.edit}>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className='table-api-key__td'>
          <span className='table-api-key__mobile'>Api key</span> **********
        </div>
        <div className='table-api-key__td'>
          <div className='only-desktop'>
            {!editing && (
              <div>
                <Button icon onClick={this.edit}>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            )}
            {editing && (
              <div>
                <button className='table-api-key__cancel' onClick={this.save}>
                  Cancel
                </button>
                <button className='table-api-key__save' onClick={this.save}>
                  Save
                </button>
              </div>
            )}
          </div>
          <div className='only-mobile'>
            {editing && (
              <div>
                <Button basic onClick={this.save}>
                  Cancel
                </Button>
                <Button color='teal' onClick={this.save}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ApiKeyItem;
