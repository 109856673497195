import React from 'react';
import { Icon, Button } from 'semantic-ui-react';

class EditKnowledgePageTabAttach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className='attach-big-table'>
        <div className='attach-big-table__head'>
          <div className='attach-big-table__ttl'>Attachments</div>
          <Button color='teal'>
            <Icon name='plus'></Icon>Add
          </Button>
        </div>
        <div className='attach-big-table__header'>
          <div className='attach-big-table__tr'>
            <div className='attach-big-table__td attach-big-table__td--th'>
              Name
            </div>
            <div className='attach-big-table__td attach-big-table__td--th'>
              Size
            </div>
            <div className='attach-big-table__td attach-big-table__td--th'>
              Date attached
            </div>
            <div className='attach-big-table__td attach-big-table__td--th'></div>
          </div>
        </div>
        <div className='attach-big-table__body'>
          <div className='attach-big-table__tr'>
            <div className='attach-big-table__td'>
              <a href='/'>
                <Icon name='attach'></Icon>My document 1.docx
              </a>
            </div>
            <div className='attach-big-table__td'>
              <div className='attach-big-table__mobile-text'>Size</div> 356 kb
            </div>
            <div className='attach-big-table__td'>
              <div className='attach-big-table__mobile-text'>Date attached</div>
              10/10/2020 11:56
            </div>
            <div className='attach-big-table__td'>
              <Button icon='trash alternate outline' />
            </div>
          </div>
          <div className='attach-big-table__tr'>
            <div className='attach-big-table__td'>
              <a href='/'>
                <Icon name='attach'></Icon>My document 1.docx
              </a>
            </div>
            <div className='attach-big-table__td'>
              <div className='attach-big-table__mobile-text'>Size</div>356 kb
            </div>
            <div className='attach-big-table__td'>
              <div className='attach-big-table__mobile-text'>Date attached</div>
              10/10/2020 11:56
            </div>
            <div className='attach-big-table__td'>
              <Button icon='trash alternate outline' />
            </div>
          </div>
          <div className='attach-big-table__tr'>
            <div className='attach-big-table__td'>
              <a href='/'>
                <Icon name='attach'></Icon>My document 1.docx
              </a>
            </div>
            <div className='attach-big-table__td'>
              <div className='attach-big-table__mobile-text'>Size</div>356 kb
            </div>
            <div className='attach-big-table__td'>
              <div className='attach-big-table__mobile-text'>Date attached</div>
              10/10/2020 11:56
            </div>
            <div className='attach-big-table__td'>
              <Button icon='trash alternate outline' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditKnowledgePageTabAttach;
