import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import {
  Dropdown,
  Input,
  Radio,
  Tab,
  Menu,
  Icon,
  Button,
  Modal,
  Checkbox,
} from 'semantic-ui-react';
import EditKnowledgePageTabAttach from '../components/EditKnowledgePageTabAttach';
import EditKnowledgePageTabHistory from '../components/EditKnowledgePageTabHistory';
import EditKnowledgePageTabDetails from '../components/EditKnowledgePageTabDetails';

const panes = [
  {
    menuItem: (
      <Menu.Item key='Details'>
        <Icon name='tasks'></Icon>
        Details
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane attached={false}>
        <EditKnowledgePageTabDetails />
      </Tab.Pane>
    ),
  },
  {
    menuItem: (
      <Menu.Item key='History'>
        <Icon name='history'></Icon>
        History
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane attached={false}>
        <EditKnowledgePageTabHistory />
      </Tab.Pane>
    ),
  },
  {
    menuItem: (
      <Menu.Item key='History'>
        <Icon name='attach'></Icon>
        Attachments (3)
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane attached={false}>
        <EditKnowledgePageTabAttach />
      </Tab.Pane>
    ),
  },
];

const authors = [
  {
    key: 'author1',
    text: 'Homura Yunosuke',
    value: 'Homura Yunosuke',
  },
  { key: 'author2', text: 'Sander Slingerland', value: 'Sander Slingerland' },
  { key: 'author3', text: 'Dameon Peterson', value: 'Dameon Peterson' },
];

const translations = [
  {
    key: 'translation1',
    text: 'English (EN)',
    value: 'English (EN)',
  },
  { key: 'translation2', text: 'Dutch (NL)', value: 'Dutch (NL)' },
];

const statuses = [
  {
    key: 'status1',
    text: 'Published',
    value: 'Published',
  },
  {
    key: 'status2',
    text: 'In review',
    value: 'In review',
  },
];
class EditKnowledgePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      author: 'Sander Slingerland',
      title: 'Paracetamol and pregnancy',
      translation: 'English (EN)',
      versions: 'published',
    };
  }
  handleChangeRadio = (e, { value }) => this.setState({ versions: value });
  handleChangeTitle = (e, { value }) => this.setState({ title: value });
  handleChangeAuthor = (e, { value }) => this.setState({ author: value });
  handleChangeTranslation = (e, { value }) =>
    this.setState({ translation: value });
  handleChangeStatus = (e, { value }) => this.setState({ status: value });
  render() {
    const { title, author, translation, status, versions } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Edit knowledge page</div>
          </div>
          <div className='app-content__body'>
            <div className='page-info'>
              <div className='page-info__header'>Page info</div>
              <div className='page-info__body'>
                <div className='page-info__row'>
                  <div className='page-info__col'>
                    <label className='page-info__label'>Title</label>
                    <Input
                      placeholder='Title'
                      value={title}
                      onChange={this.handleChangeTitle}
                    />
                  </div>
                  <div className='page-info__col'>
                    <label className='page-info__label'>Author</label>
                    <Dropdown
                      options={authors}
                      placeholder='Author'
                      selection
                      value={author}
                      onChange={this.handleChangeAuthor}
                    />
                  </div>
                  <div className='page-info__col'>
                    <label className='page-info__label'>Translation</label>
                    <Dropdown
                      options={translations}
                      placeholder='Translation'
                      selection
                      value={translation}
                      onChange={this.handleChangeTranslation}
                    />
                  </div>
                  <div className='page-info__col'>
                    <label className='page-info__label'>Status</label>
                    <Dropdown
                      options={statuses}
                      placeholder='Status'
                      selection
                      value={status}
                      onChange={this.handleChangeStatus}
                    />
                  </div>
                </div>
              </div>
              <div className='page-info__footer'>
                <div className='page-info__versions'>
                  <span>Other versions:</span>
                  <Radio
                    label='Published'
                    className='published'
                    name='radioGroup'
                    onChange={this.handleChangeRadio}
                    value='published'
                    checked={versions === 'published'}
                  />
                  <Radio
                    label='In review'
                    className='in-review'
                    name='radioGroup'
                    onChange={this.handleChangeRadio}
                    value='in-review'
                    checked={versions === 'in-review'}
                  />
                </div>
                <div className='page-info__updated'>
                  Last updated 16/04/2020
                </div>
              </div>
            </div>
            <div className='edit-tabs'>
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
              <div className='website-tabs__footer website-tabs__footer--space-between'>
                <div>
                  <Button basic>Cancel</Button>
                </div>
                <div>
                  <Button basic>Preview</Button>

                  <Modal
                    trigger={<Button color='teal'>Save Changes</Button>}
                    className='publish-modal'
                  >
                    <div className='publish-modal__wrap'>
                      <div className='publish-modal__ttl'>
                        Do you want to unpublish translations?
                      </div>
                      <div className='publish-modal__text'>
                        There are published translations for this knowledge
                        page. Publishing a new English version can mean the
                        translations need an update and information on the
                        website is outdated.
                        <br />
                        <br />
                        Do you want to unpublish existing translations?
                        <br />
                        <br />
                        <Checkbox label='Notify by email' />
                      </div>
                      <div className='publish-modal__footer'>
                        <Button color='teal'>Yes</Button>
                        <Button basic>No</Button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default EditKnowledgePage;
