import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import TextEditor from '../components/TextEditor';
import { Input, Form, TextArea, Button } from 'semantic-ui-react';

class CreateNewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Create new page</div>
          </div>
          <div className='app-content__body'>
            <div className='create-new-page'>
              <div className='create-new-page__row'>
                <div className='create-new-page__left'>
                  <div className='new-page'>
                    <div className='new-page__elem'>
                      <div className='new-page__ttl'>Page title</div>
                      <Input fluid placeholder='Enter page title' />
                    </div>
                    <div className='new-page__elem'>
                      <div className='new-page__ttl'>Add content</div>
                      <TextEditor />
                    </div>
                  </div>
                </div>
                <div className='create-new-page__right'>
                  <div className='widget-info'>
                    <div className='widget-info__ttl'>
                      Add contact information widget
                    </div>
                    <div className='widget-info__box'>
                      <div className='widget-info__elem'>
                        <div className='widget-info__name'>Title</div>
                        <Input fluid placeholder='Enter widget title' />
                      </div>
                      <div className='widget-info__elem'>
                        <div className='widget-info__name'>Main text</div>
                        <Form>
                          <TextArea placeholder='Enter main text' />
                        </Form>
                      </div>
                      <div className='widget-info__elem'>
                        <div className='widget-info__name'>Add logotype</div>
                        <div className='file-upload'>
                          <input
                            type='file'
                            className='file-upload__input'
                            accept='.png, .jpg, .jpeg, .svg'
                          />
                          <div className='file-upload__text'>
                            Accepted formats png, jpg or svg
                          </div>
                          <div className='file-upload__btn'></div>
                        </div>
                      </div>
                      <div className='widget-info__elem'>
                        <div className='widget-info__name'>
                          Add external link
                        </div>
                        <Input fluid />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='website-tabs__footer  website-tabs__footer--space-between'>
                <div>
                  <Button basic>Cancel</Button>
                </div>
                <div>
                  <Button basic>Preview</Button>
                  <Button color='teal'>Save</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default CreateNewPage;
