import React from 'react';
import { Icon, Button } from 'semantic-ui-react';

class TabPagesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isEditing } = this.state;
    const { num } = this.props;
    return (
      <div className='table-pages__tr'>
        <div className='table-pages__td'>{num}</div>
        <div className='table-pages__td'>Paracetamol and pregnancy </div>
        <div className='table-pages__td'>
          <a href='/' className='table-pages__link'>
            <Icon color='teal' name='linkify' />
            www.page.com
          </a>
        </div>
        <div className='table-pages__td'>
          <div>
            <Button icon>
              <Icon color='teal' name='pencil' />
            </Button>
            <Button icon>
              <Icon color='teal' name='trash alternate outline' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TabPagesRow;
