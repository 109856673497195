import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import ApiKyeItem from '../components/ApiKeyItem';
import { Input, Button } from 'semantic-ui-react';

class APiKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = { created: false };
    this.generate = this.generate.bind(this);
  }
  generate(e) {
    e.preventDefault();
    this.setState({ created: true });
  }
  render() {
    const { created } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>API keys</div>
          </div>
          <div className='app-content__body'>
            <div className='create-api-key'>
              {!created && (
                <div>
                  <label className='create-api-key__label'>API key name</label>
                  <Input />
                  <Button color='teal' onClick={this.generate}>
                    Generate
                  </Button>
                </div>
              )}
              {created && (
                <div>
                  <table>
                    <tr>
                      <th>API key name </th>
                      <th>API key </th>
                    </tr>
                    <tr>
                      <td>key</td>
                      <td>adh284hrfla</td>
                    </tr>
                  </table>
                  <Button basic>Remove</Button>
                  <div className='create-api-key__info'>
                    Please copy and share the API key with the relevant third
                    party.
                  </div>
                </div>
              )}
            </div>

            <div className='table-api-key'>
              <div className='table-api-key__head'>
                <div className='table-api-key__tr'>
                  <div className='table-api-key__td table-api-key__td--th'>
                    API key name
                  </div>
                  <div className='table-api-key__td table-api-key__td--th'>
                    API key
                  </div>
                  <div className='table-api-key__td table-api-key__td--th'></div>
                </div>
              </div>
              <div className='table-api-key__body'>
                <ApiKyeItem />
                <ApiKyeItem />
                <ApiKyeItem />
                <ApiKyeItem />
                <ApiKyeItem />
                <ApiKyeItem />
                <ApiKyeItem />
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default APiKey;
