import React from 'react';

class KnowledgeRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
  }
  toggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }
  render() {
    const { isOpen } = this.state;
    const { num } = this.props;
    let nameClass = '';
    if (isOpen) {
      nameClass = 'active';
    }
    return (
      <div>
        <div className='table-knowledge__tr'>
          <div className='table-knowledge__td'>{num}</div>
          <div className='table-knowledge__td' onClick={this.toggle}>
            <span className={nameClass}>Paracetamol and pregnancy</span>
          </div>
          <div className='table-knowledge__td'>
            <div className='table-knowledge__mobile-text'>Translation</div>
            <span className='table-knowledge__lang'>EN</span>
            <span className='status status--green' data-lang='EN'>
              Published
            </span>
            <span className='status status--blue' data-lang='EN'>
              In review
            </span>
            <span className='table-knowledge__more' onClick={this.toggle}>
              + 2 more
            </span>
            <div className='only-mobile'>
              {isOpen && (
                <div className='table-knowledge__mobile-lang'>
                  <span className='status' data-lang='NL'>
                    Concept
                  </span>
                  <br />
                  <span className='status status--red' data-lang='Ge'>
                    Removed
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='table-knowledge__td'>
            <div className='table-knowledge__mobile-text'>Date created</div>
            12/12/2019
          </div>
          <div className='table-knowledge__td'>
            <div className='table-knowledge__mobile-text'>Last updated</div>
            10/01/2020
          </div>
          <div className='table-knowledge__td'>
            <div className='table-knowledge__mobile-text'>Last updated by</div>
            Babila Ebwélé
          </div>
        </div>
        {isOpen && (
          <div className='only-desktop'>
            <div className='table-knowledge__tr table-knowledge__tr--additional'>
              <div className='table-knowledge__td'></div>
              <div className='table-knowledge__td'></div>
              <div className='table-knowledge__td'>
                NL
                <span className='status'>Concept</span>
              </div>
              <div className='table-knowledge__td'>12/12/2019</div>
              <div className='table-knowledge__td'>10/01/2020</div>
              <div className='table-knowledge__td'>Wulf Kindler</div>
            </div>
            <div className='table-knowledge__tr table-knowledge__tr--additional'>
              <div className='table-knowledge__td'></div>
              <div className='table-knowledge__td'></div>
              <div className='table-knowledge__td'>
                GE
                <span className='status status--red'>Removed</span>
              </div>
              <div className='table-knowledge__td'>12/12/2019</div>
              <div className='table-knowledge__td'>10/01/2020</div>
              <div className='table-knowledge__td'>Wulf Kindler</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default KnowledgeRow;
