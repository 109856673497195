import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import ImgNews from '../images/img-sidebar-news.jpg';
import SearchBar from '../components/SearchBar';
import ChooseTranslation from '../components/ChooseTranslation';
import {
  Transition,
  Button,
  Icon,
  Tab,
  Form,
  TextArea,
} from 'semantic-ui-react';
import References from '../components/References';
import { Link } from 'react-router-dom';
import Ava from '../images/ico-ava.svg';

const panes = [
  {
    menuItem: 'All (1)',
    render: () => (
      <Tab.Pane attached={false}>
        <div className='preview-comments__list'>
          <div className='preview-comments__item'>
            <div className='comment-box'>
              <div className='comment-box__header'>
                <div className='comment-box__ava'>
                  <img src={Ava} alt='' />
                </div>
                <div className='comment-box__name'>Hattie Guzman</div>
                <div className='comment-box__date'>10/10/2019 10:12</div>
              </div>
              <div className='comment-box__body'>
                <div className='comment-box__quote'>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip.
                </div>
                <div className='comment-box__text'>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Pregnancy (1)',
    render: () => (
      <Tab.Pane attached={false}>
        <div className='preview-comments__list'>
          <div className='preview-comments__item'>
            <div className='comment-box'>
              <div className='comment-box__header'>
                <div className='comment-box__ava'>
                  <img src={Ava} alt='' />
                </div>
                <div className='comment-box__name'>Hattie Guzman</div>
                <div className='comment-box__date'>10/10/2019 10:12</div>
              </div>
              <div className='comment-box__body'>
                <div className='comment-box__quote'>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip.
                </div>
                <div className='comment-box__text'>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Lactation (0)',
    render: () => (
      <Tab.Pane attached={false}>
        <div className='preview-comments__list'>
          <div className='preview-comments__item'>
            <div className='comment-box'>
              <div className='comment-box__header'>
                <div className='comment-box__ava'>
                  <img src={Ava} alt='' />
                </div>
                <div className='comment-box__name'>Hattie Guzman</div>
              </div>
              <div className='comment-box__body'>
                <Form>
                  <TextArea placeholder='Add new comment' />
                </Form>
                <div className='comment-box__actions'>
                  <Button color='teal'>Save</Button>
                  <Button basic>Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab.Pane>
    ),
  },
];

class PreviewKnowledge extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef1 = React.createRef();
    this.wrapperRef2 = React.createRef();
    this.state = {
      showMore1: false,
      showMore2: false,
      open1: false,
      open2: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.handleClickOutside1 = this.handleClickOutside1.bind(this);
    this.handleClick1 = this.handleClick1.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
  }
  toggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      showMore1: !state.showMore1,
    }));
  }
  toggle2(e) {
    e.preventDefault();
    this.setState((state) => ({
      showMore2: !state.showMore2,
    }));
  }
  handleClickOutside1(event) {
    if (this.wrapperRef1 && !this.wrapperRef1.current.contains(event.target)) {
      this.handleClose1();
    }
  }
  handleClick1() {
    this.setState((prevState) => ({ open1: !prevState.open1 }));
  }
  handleClose1() {
    this.setState({ open1: false });
  }
  handleClick2() {
    this.setState((prevState) => ({ open2: !prevState.open2 }));
  }
  handleClose2() {
    this.setState({ open2: false });
  }
  handleClickOutside2(event) {
    if (this.wrapperRef2 && !this.wrapperRef2.current.contains(event.target)) {
      this.handleClose2();
    }
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside1);
    document.addEventListener('click', this.handleClickOutside2);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside1);
    document.removeEventListener('click', this.handleClickOutside2);
  }
  render() {
    const { showMore1, showMore2, open1, open2 } = this.state;
    let btnClass1 = 'content-block__more';
    if (showMore1) {
      btnClass1 = 'content-block__more content-block__more--active';
    }
    let btnClass2 = 'content-block__more';
    if (showMore2) {
      btnClass2 = 'content-block__more content-block__more--active';
    }
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header app-content__header--mobile-wrap'>
            <div className='app-content__ttl'>Knowledge page preview</div>
            <div className='preview-actions'>
              <div className='preview-actions__elem' ref={this.wrapperRef1}>
                <Button onClick={this.handleClick1}>
                  <Icon name='attach' />
                  <span className='only-desktop'>ATTACHMENTS</span>
                  (3)
                </Button>
                {open1 && (
                  <div className='preview-actions__dropdown'>
                    <div className='table-attach'>
                      <div className='table-attach__header'>
                        <div className='table-attach__tr'>
                          <div className='table-attach__td'>Name</div>
                          <div className='table-attach__td'>Size</div>
                          <div className='table-attach__td'>Date attached</div>
                        </div>
                      </div>
                      <div className='table-attach__body'>
                        <div className='table-attach__tr'>
                          <div className='table-attach__td'>
                            <a href='/'>
                              <Icon name='attach'></Icon>My document 1.docx
                            </a>
                          </div>
                          <div className='table-attach__td'>356 kb</div>
                          <div className='table-attach__td'>
                            10/10/2020 11:56
                          </div>
                        </div>
                        <div className='table-attach__tr'>
                          <div className='table-attach__td'>
                            <a href='/'>
                              <Icon name='attach'></Icon>My document 1.docx
                            </a>
                          </div>
                          <div className='table-attach__td'>356 kb</div>
                          <div className='table-attach__td'>
                            10/10/2020 11:56
                          </div>
                        </div>
                        <div className='table-attach__tr'>
                          <div className='table-attach__td'>
                            <a href='/'>
                              <Icon name='attach'></Icon>My document 1.docx
                            </a>
                          </div>
                          <div className='table-attach__td'>356 kb</div>
                          <div className='table-attach__td'>
                            10/10/2020 11:56
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='preview-actions__elem' ref={this.wrapperRef2}>
                <Button onClick={this.handleClick2}>
                  <Icon name='comment alternate outline'></Icon>
                  Comment
                </Button>
                {open2 && (
                  <div className='preview-actions__dropdown'>
                    <div className='preview-comments'>
                      <Tab
                        menu={{ secondary: true, pointing: true }}
                        panes={panes}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='preview-actions__elem'>
                <Button as={Link} to='/knowledge/edit' color='teal'>
                  Edit
                </Button>
              </div>
            </div>
          </div>
          <div className='app-content__body'>
            <div className='knowledge-preview'>
              <section className='detail-page'>
                <div>
                  <div className='detail-page__header'>
                    <div className='detail-page__ttl'>Paracetamol</div>
                    <div className='detail-page__search-bar'>
                      <SearchBar value='Paracetamol' />
                    </div>
                  </div>
                  <div className='detail-page__body'>
                    <div className='detail-page__col2'>
                      <div className='content-block' id='pregnancy'>
                        <div className='content-block__header'>
                          <div className='content-block__ttl'>Pregnancy</div>
                          <div className='content-block__action'>
                            <ChooseTranslation />
                          </div>
                        </div>
                        <div className='content-block__body'>
                          <p>
                            Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip. Duis aute irure
                            dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur.
                          </p>
                          <p>
                            Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip. Duis aute irure
                            dolor in reprehenderit in voluptate velit esse
                          </p>
                          <div className='content-block__sub-ttl'>Details</div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua…
                          </p>

                          <Transition
                            visible={showMore1}
                            animation={showMore1 ? 'fade down' : 'fade up'}
                            duration={500}
                          >
                            <div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua…
                              </p>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua…
                              </p>
                            </div>
                          </Transition>

                          <a
                            href='/'
                            className={btnClass1}
                            onClick={this.toggle}
                          >
                            {!showMore1 && 'Show more'}
                            {showMore1 && 'Less'}
                          </a>
                          <References />
                        </div>
                      </div>
                      <div className='content-block'>
                        <div className='content-block__header' id='location'>
                          <div className='content-block__ttl'>
                            Breastfeeding
                          </div>
                          <div className='content-block__action'>
                            <ChooseTranslation />
                          </div>
                        </div>
                        <div className='content-block__body'>
                          <p>
                            Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip. Duis aute irure
                            dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur.
                          </p>
                          <p>
                            Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip. Duis aute irure
                            dolor in reprehenderit in voluptate velit esse
                          </p>
                          <div className='content-block__sub-ttl'>Details</div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua…
                          </p>
                          <Transition
                            visible={showMore2}
                            animation={showMore2 ? 'fade down' : 'fade up'}
                            duration={500}
                          >
                            <div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua…
                              </p>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua…
                              </p>
                            </div>
                          </Transition>

                          <a
                            href='/'
                            className={btnClass2}
                            onClick={this.toggle2}
                          >
                            {!showMore2 && 'Show more'}
                            {showMore2 && 'Less'}
                          </a>
                          <References />
                        </div>
                      </div>
                      <div className='content-block'>
                        <div className='content-block__header'>
                          <div className='content-block__ttl'>
                            External links
                          </div>
                        </div>
                        <div className='content-block__body'>
                          <ul className='content-block__link'>
                            <li>
                              <a href='/'>
                                Local knowledge bank of The Netherlands
                                Pharmacovigilance Centre Lareb
                              </a>
                            </li>
                            <li>
                              <a href='/'>
                                National registers of authorised medicines
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className='content-block__footer'>
                          Ut enim ad minim veniam, quis nostrud exercitation
                          ullamco laboris nisi ut aliquip. Duis aute irure dolor
                          in reprehenderit in voluptate velit esse cillum dolore
                          eu fugiat nulla pariatu{' '}
                          <div className='content-block__published'>
                            Last updated <b>16/04/2020</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='detail-page__col3'>
                      <div className='sidebar-pages sidebar-pages--mb'>
                        <div className='sidebar-pages__ttl'>Related pages</div>
                        <ul className='sidebar-pages__list'>
                          <li className='sidebar-pages__item'>
                            <a href='/' className='sidebar-pages__link'>
                              Nam dapibus nisl vitae elit fringilla rutrum.
                            </a>
                          </li>
                          <li className='sidebar-pages__item'>
                            <a href='/' className='sidebar-pages__link'>
                              Nam dapibus nisl vitae elit fringilla rutrum.
                            </a>
                          </li>
                          <li className='sidebar-pages__item'>
                            <a href='/' className='sidebar-pages__link'>
                              Nam dapibus nisl vitae elit fringilla rutrum.
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className='sidebar-pages'>
                        <div className='sidebar-pages__ttl'>
                          Related In focus
                        </div>
                        <ul className='sidebar-pages__list'>
                          <li className='sidebar-pages__item'>
                            <a href='/' className='sidebar-pages__infocus'>
                              <img src={ImgNews} alt='' />
                              Nam dapibus nisl vitae elit fringilla rutrum.
                            </a>
                          </li>
                          <li className='sidebar-pages__item'>
                            <a href='/' className='sidebar-pages__infocus'>
                              <img src={ImgNews} alt='' />
                              Nam dapibus nisl vitae elit fringilla rutrum.
                            </a>
                          </li>
                          <li className='sidebar-pages__item'>
                            <a href='/' className='sidebar-pages__infocus'>
                              <img src={ImgNews} alt='' />
                              Nam dapibus nisl vitae elit fringilla rutrum.
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default PreviewKnowledge;
