import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import { Breadcrumb, Modal, Icon } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';

class MedicalConditionsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {

        const sections = [
            { key: 'Home', content: 'Home', link: true },
            { key: 'Maternal Medical Conditions', content: 'Maternal Medical Conditions', link: true},
            { key: this.props.location.state.title, content: this.props.location.state.title, active: true }
        ];

        return (
            <BaseLayout>
                <section className='breadcrumbs'>
                    <div className='container'>
                        <Breadcrumb icon='right angle' sections={sections} />
                    </div>
                </section>
                <section className='about-us'>
                    <div className='container'>
                        <div className='about-us__content'>
                            <div className='about-us__ttl'>{this.props.location.state.title}</div>
                                <div className='about-us__text'>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.location.state.details }} />
                                    <br />
                                    <p>
                                    Maecenas at dolor quis odio consectetur eleifend in sit amet risus. Nunc tincidunt quam eu viverra pellentesque. Sed volutpat sem aliquam est sodales, ac placerat metus euismod. Vivamus quam ex, condimentum eu maximus non, pretium vitae magna. Duis ac hendrerit nisl. Phasellus lacus nisi, maximus ut dolor sit amet, pellentesque aliquam enim. Maecenas dictum interdum mauris commodo hendrerit. Donec ipsum lectus, accumsan sit amet mi quis, bibendum tincidunt ante. Ut urna dui, volutpat in sollicitudin quis, porttitor ut quam. Nulla a porttitor dolor, a rhoncus lacus. Nam sodales neque non vulputate laoreet. Duis enim mauris, venenatis non aliquet eget, ullamcorper at nisl. Sed egestas urna ut nulla efficitur vehicula. Sed ullamcorper molestie placerat. Praesent ultricies venenatis enim, a auctor felis pulvinar ut. Sed mattis orci quis justo convallis, sit amet congue mauris rutrum.
                                    <br/><br/>
                                    Etiam ac viverra sem. Maecenas iaculis velit eu semper pulvinar. Cras urna sem, mattis eget lectus ac, viverra bibendum turpis. In rhoncus nisl ut odio dignissim scelerisque. Phasellus in convallis purus. Maecenas tincidunt lacus sit amet elementum eleifend. Nulla pretium, quam nec lacinia finibus, ex ligula varius lectus, vitae dictum massa ligula eget massa. Aenean ac scelerisque lacus. Aenean tempor vestibulum metus, nec scelerisque libero. Donec ut dignissim ex. Ut maximus elementum eros, sed consequat justo blandit nec. Suspendisse leo neque, blandit vel viverra sed, tempus in dui. Phasellus ultricies nisl non felis sagittis, sed pretium diam auctor. Vestibulum eget sem quis arcu ultricies aliquet. Praesent rutrum faucibus turpis, et dignissim dui egestas eget.
                                    Maecenas at dolor quis odio consectetur eleifend in sit amet risus. Nunc tincidunt quam eu viverra pellentesque. Sed volutpat sem aliquam est sodales, ac placerat metus euismod. Vivamus quam ex, condimentum eu maximus non, pretium vitae magna. Duis ac hendrerit nisl. Phasellus lacus nisi, maximus ut dolor sit amet, pellentesque aliquam enim. Maecenas dictum interdum mauris commodo hendrerit. Donec ipsum lectus, accumsan sit amet mi quis, bibendum tincidunt ante. Ut urna dui, volutpat in sollicitudin quis, porttitor ut quam. Nulla a porttitor dolor, a rhoncus lacus. Nam sodales neque non vulputate laoreet. Duis enim mauris, venenatis non aliquet eget, ullamcorper at nisl. Sed egestas urna ut nulla efficitur vehicula. Sed ullamcorper molestie placerat. Praesent ultricies venenatis enim, a auctor felis pulvinar ut. Sed mattis orci quis justo convallis, sit amet congue mauris rutrum.
                                    <br /><br />
                                    Etiam ac viverra sem. Maecenas iaculis velit eu semper pulvinar. Cras urna sem, mattis eget lectus ac, viverra bibendum turpis. In rhoncus nisl ut odio dignissim scelerisque. Phasellus in convallis purus. Maecenas tincidunt lacus sit amet elementum eleifend. Nulla pretium, quam nec lacinia finibus, ex ligula varius lectus, vitae dictum massa ligula eget massa. Aenean ac scelerisque lacus. Aenean tempor vestibulum metus, nec scelerisque libero. Donec ut dignissim ex. Ut maximus elementum eros, sed consequat justo blandit nec. Suspendisse leo neque, blandit vel viverra sed, tempus in dui. Phasellus ultricies nisl non felis sagittis, sed pretium diam auctor. Vestibulum eget sem quis arcu ultricies aliquet. Praesent rutrum faucibus turpis, et dignissim dui egestas eget.
                                    </p>
                                </div>
                        </div>
                    </div>
                    <div className='maternal-conditions__btn'>
                       <Button onClick={this.goBack} color='teal'>Back</Button>
                    </div>
                    <br /><br />
                </section>
            </BaseLayout>
        );
    }
}

export default MedicalConditionsDetail;

