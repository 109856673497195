import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

function MaternalConditionsItem(props) {
  return (
      <li className='maternal-conditions__item'>
          <Link to={{pathname: '/Detail-page', state: { title: props.title } }} className='maternal-news'>
          <div className='maternal-news__tag'>
              <span className='label'>{props.tag}</span>
          </div>
          <div className='maternal-news__ttl'>
                  {props.title}
          </div>
          <p className='maternal-news__text'>
                  {props.contentshort}
          </p>
          <span className='maternal-news__link'>Read more</span>
        </Link>
      </li>
  );
}
export default MaternalConditionsItem;
