import React from 'react';
import { Button, Icon, Dropdown, Input } from 'semantic-ui-react';

const pages = [
  {
    key: 'page1',
    text: 'About us',
    value: 'About us',
  },
  { key: 'page2', text: 'Homepage', value: 'Homepage' },
  { key: 'page3', text: 'How we work', value: 'How we work' },
];

class TabMenuRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false, pages, page: 'Homepage', name: 'Home' };
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  handleChangePage = (e, { value }) => this.setState({ page: value });
  edit(e) {
    e.preventDefault();
    this.setState({ isEditing: true });
  }
  save(e) {
    e.preventDefault();
    this.setState({ isEditing: false });
  }
  render() {
    const { isEditing, page, name } = this.state;
    return (
      <div className='table-menu__tr'>
        <div className='table-menu__td'>1</div>
        <div className='table-menu__td'>
          {!isEditing && name}
          {isEditing && (
            <Input value={name} onChange={this.handleChangeName}></Input>
          )}
        </div>
        <div className='table-menu__td'>
          {!isEditing && page}
          {isEditing && (
            <Dropdown
              options={this.state.pages}
              placeholder='Choose'
              selection
              value={page}
              onChange={this.handleChangePage}
            />
          )}
        </div>
        <div className='table-menu__td'>
          {!isEditing && (
            <div className='table-menu__edit-btn'>
              <Button icon>
                <Icon color='teal' name='long arrow alternate up' />
              </Button>
              <Button icon>
                <Icon color='teal' name='long arrow alternate down' />
              </Button>
              <Button icon onClick={this.edit}>
                <Icon color='teal' name='pencil' />
              </Button>
              <Button icon>
                <Icon color='teal' name='trash alternate outline' />
              </Button>
            </div>
          )}
          <div className='only-desktop'>
            {isEditing && (
              <div>
                <button
                  className='table-medical-conditions__cancel'
                  onClick={this.save}
                >
                  Cancel
                </button>
                <button
                  className='table-medical-conditions__save'
                  onClick={this.save}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <div className='only-mobile'>
            {isEditing && (
              <div>
                <Button basic onClick={this.save}>
                  Cancel
                </Button>
                <Button color='teal' onClick={this.save}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TabMenuRow;
