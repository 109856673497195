import React from 'react';
import BgImage from '../images/img-in-focus-1.jpg';
import { BrowserRouter as Router, Link } from 'react-router-dom';

function InFocusItem(props) {
  return (
    <li className='in-focus__item'>
           <Link to={{ pathname: '/InFocusDetail', state: { title: props.title, details: props.details } }} className='focus-news'>
          <div
            className='focus-news__img'
            style={{ backgroundImage: `url(${BgImage})` }}
          ></div>
          <div className='focus-news__info'>
            <div className='focus-news__ttl'>
                  {props.title}
            </div>
            <p className='focus-news__text'>
                 {props.short}
            </p>
            <span className='focus-news__link'>Read more</span>
          </div>
        </Link>
    </li>
  );
}
export default InFocusItem;
