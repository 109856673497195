import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/HeaderForDevPage';
import SearchBar from '../components/SearchBar';
import { Highlight } from 'react-fast-highlight';

function Api() {
  const codeString = `<?xml version="1.0" encoding="UTF-8"?>
  <request version="1.0">
    <merchant>
    <id>103662</id>
    <signature>4ffc6115832fe6526710e7c7a15aeb11eeecd063</signature>
  </merchant>
  <data>
    <oper>cmt</oper>
    <wait>30</wait>
    <test>0</test>
    <payment id="2657374">
      <prop name="b_card_or_acc" value="b_card_or_acc" />
      <prop name="amt" value="10" />
      <prop name="ccy"  value="UAH" />
      <prop name="b_name" value="FIO" />
      <prop name="details" value="testVisa" />
    </payment>
  </data>
  </request>`;
  return (
    <div className='wrapper'>
      <div className='content'>
        <Header headerWithShadow />
        <div className='api-page'>
          <div className='api-page__sidebar'>
            <ul className='api-page__menu'>
              <li className='api-page__item'>
                <a href='/' className='api-page__link'>
                  Content
                </a>
              </li>
              <li className='api-page__item'>
                <a href='/' className='api-page__link api-page__link--active'>
                  Content
                </a>
                <ul className='api-page__sub-menu'>
                  <li className='api-page__sub-item'>
                    <a
                      href='/'
                      className='api-page__link api-page__link--highlight'
                    >
                      Page
                    </a>
                  </li>
                  <li className='api-page__sub-item'>
                    <a href='/' className='api-page__link'>
                      Application
                    </a>
                  </li>
                  <li className='api-page__sub-item'>
                    <a href='/' className='api-page__link'>
                      Application
                    </a>
                  </li>
                </ul>
              </li>
              <li className='api-page__item'>
                <a href='/' className='api-page__link'>
                  Content
                </a>
              </li>
              <li className='api-page__item'>
                <a href='/' className='api-page__link'>
                  Content
                </a>
              </li>
            </ul>
          </div>
          <div className='api-page__content'>
            <div className='api-page__container'>
              <div className='api-page__ttl'>Paracetamol</div>
              <div className='api-page__search-bar'>
                <SearchBar />
              </div>
              <div className='api-page__sub-ttl'>
                Request for <span>‘Paracetamol’</span>:
              </div>
              <div className='code-highlight'>
                <div className='code-highlight__header'>XML</div>
                <div className='code-highlight__body'>
                  <Highlight languages={['xml']} className='code'>
                    {codeString}
                  </Highlight>
                </div>
              </div>
              <div className='api-page__sub-ttl'>
                Response for <span>‘Paracetamol’</span>:
              </div>
              <div className='code-highlight'>
                <div className='code-highlight__header'>XML</div>
                <div className='code-highlight__body'>
                  <Highlight languages={['xml']} className='code'>
                    {codeString}
                  </Highlight>
                </div>
              </div>
              <div className='api-page__delimiter'></div>
              <div className='api-page__sub-ttl'>Documentation</div>
              <div className='api-page__desc'>Request parameters</div>
              <table className='api-page__table'>
                <tbody>
                  <tr>
                    <th>Parameter</th>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td>id</td>
                    <td>
                      Page ID. No access token is required to access this field
                    </td>
                  </tr>
                  <tr>
                    <td>signature</td>
                    <td>
                      The request signature is calculated as follows{' '}
                      <span>
                        (PHP): $ sign = sha1 (md5 ($ data. $ Password)); $ data
                      </span>{' '}
                      - the content of the data tag of this request; $ password
                      - merchant’s personal password obtained during
                      registration is located in the merchant’s settings menu
                    </td>
                  </tr>
                  <tr>
                    <td>payment id</td>
                    <td>
                      The unique payment identifier assigned by the payment
                      partner. It is repeated in response to a request, stored
                      in the Privat24 database, serves to unambiguously compare
                      transactions on the payment partner side with operations
                      in privat24.
                    </td>
                  </tr>
                  <tr>
                    <td>ccy</td>
                    <td>Currency (UAH, EUR, USD)</td>
                  </tr>
                  <tr>
                    <td>b_name</td>
                    <td>Name of the recipient</td>
                  </tr>
                  <tr>
                    <td>details</td>
                    <td>Purpose of payment</td>
                  </tr>
                </tbody>
              </table>
              <div className='api-page__desc'>Response parameters</div>
              <table className='api-page__table'>
                <tbody>
                  <tr>
                    <th>Parameter</th>
                    <th>Description</th>
                  </tr>
                  <tr>
                    <td>id</td>
                    <td>
                      Page ID. No access token is required to access this field
                    </td>
                  </tr>
                  <tr>
                    <td>signature</td>
                    <td>
                      The request signature is calculated as follows{' '}
                      <span>
                        (PHP): $ sign = sha1 (md5 ($ data. $ Password)); $ data
                      </span>{' '}
                      - the content of the data tag of this request; $ password
                      - merchant’s personal password obtained during
                      registration is located in the merchant’s settings menu
                    </td>
                  </tr>
                  <tr>
                    <td>payment id</td>
                    <td>
                      The unique payment identifier assigned by the payment
                      partner. It is repeated in response to a request, stored
                      in the Privat24 database, serves to unambiguously compare
                      transactions on the payment partner side with operations
                      in privat24.
                    </td>
                  </tr>
                  <tr>
                    <td>ccy</td>
                    <td>Currency (UAH, EUR, USD)</td>
                  </tr>
                  <tr>
                    <td>b_name</td>
                    <td>Name of the recipient</td>
                  </tr>
                  <tr>
                    <td>details</td>
                    <td>Purpose of payment</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Api;
