import React from 'react';
import AppSidebar from '../components/AppSidebar';
import Ava from '../images/img-ava.jpg';
import { Dropdown } from 'semantic-ui-react';
import icoProfile from '../images/ico-profile.svg';
import icoLogout from '../images/ico-logout.svg';
import icoHamburger from '../images/ico-hamburger.svg';
import IcoClose from '../images/ico-close-popup.svg';

const trigger = (
  <div className='app-page__user'>
    <span>Sander Slingerland</span>
    <img src={Ava} alt='' />
  </div>
);

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpenMobileMenu: false };
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
  }
  openMobileMenu() {
    this.setState((state) => ({
      isOpenMobileMenu: true,
    }));
    document.body.style.overflow = 'hidden';
  }
  closeMobileMenu() {
    this.setState((state) => ({
      isOpenMobileMenu: false,
    }));
    document.body.style.overflow = 'auto';
  }
  render() {
    const { isOpenMobileMenu } = this.state;
    return (
      <div className='wrapper'>
        {isOpenMobileMenu && (
          <div className='mobile-menu'>
            <button
              className='mobile-menu__close'
              onClick={this.closeMobileMenu}
            >
              <img src={IcoClose} alt='' />
            </button>
            <ul className='mobile-menu__list'>
              <li>
                <a href='/'>Knowledge pages</a>
              </li>
              <li>
                <a href='/'>Drug list</a>
              </li>
              <li>
                <a href='/'>Medical conditions</a>
              </li>
              <li>
                <a href='/'>In focus pages</a>
              </li>
              <li>
                <a href='/'>Translations</a>
              </li>
              <li>
                <a href='/'>Website</a>
              </li>
              <li>
                <a href='/'>Users</a>
              </li>
            </ul>
          </div>
        )}
        <div className='app-page'>
          <div className='app-page__sidebar'>
            <AppSidebar />
          </div>
          <div className='app-page__content'>
            <div className='app-page__header'>
              <div
                className='app-page__hamburger'
                onClick={this.openMobileMenu}
              >
                <img src={icoHamburger} alt='' />
              </div>
              <span>
                <Dropdown inline trigger={trigger} direction='left'>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <img src={icoProfile} alt='' />
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <img src={icoLogout} alt='' />
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
            <div className='app-page__body'>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminLayout;
