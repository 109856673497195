import React from 'react';
import Logo from '../images/mums.png';
import { BrowserRouter as Router, Link } from 'react-router-dom';

import { Input, Button } from 'semantic-ui-react';

function Login() {
  return (
    <div className='login-page'>
      <div className='login-page__bg'></div>
      <div className='login-page__header'>
        <div className='container'>
          <img src={Logo} alt='' width='250'></img>
        </div>
      </div>
      <div className='login-page__body'>
        <div className='container'>
          <div className='login-page__form'>
            <div className='login-page__ttl'>Login</div>
            <div className='login-page__sub-ttl'>
              Please login with your email address and password
            </div>
            <div className='login-page__group'>
              <label>Email</label>
              <Input placeholder='Enter email' />
            </div>
            <div className='login-page__group'>
              <label>Password</label>
              <Input placeholder='Enter password' type='password' />
            </div>
                <div className='login-page__btn'>
                   <Link to='/admin/knowledge'>
                        <Button color='blue'>Login</Button>
                  </Link>
            </div>
            <div className='login-page__link'>
                <a href="/forgot-password">Forgot your password?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

