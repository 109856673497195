import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import { Breadcrumb, Modal, Icon } from 'semantic-ui-react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

class MedicalConditionsPub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
  
        const sections = [
            { key: 'Home', content: 'Home', link: true },
            { key: 'Maternal Medical Conditions', content: 'Maternal Medical Conditions', active: true },
        ];
        return (
            <BaseLayout>
                <section className='breadcrumbs'>
                    <div className='container'>
                        <Breadcrumb icon='right angle' sections={sections} />
                    </div>
                </section>
                <section className='about-us'>
                    <div className='container'>
                        <div className='about-us__content'>
                            <div className='about-us__ttl'>Maternal medical conditions</div>
                                <div className='app-content'>
                                    <div className='app-content__body'>
                                        <div className='table-medical-conditions'>
                                            <div className='table-medical-conditions__head'>
                                            <div className='table-medical-conditions__tr'>
                                                <div className='table-medical-conditions__td table-medical-conditions__td--th'>
                                                        Nr.
                                                    </div>
                                                <div className='table-medical-conditions__td table-medical-conditions__td--th'>
                                                        Indication
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='table-medical-conditions__body'>
                                            <div className='table-medical-conditions__tr'>
                                                <div className='table-medical-conditions__td'>1</div>
                                                <Link to={{
                                                    pathname: '/Detail-page', state: {
                                                        title: "Rheumatoid Arthritis" } }}>
                                                    <div className='table-medical-conditions__td' style={{ width: "100%" }}>Rheumatoid Arthritis</div>
                                                </Link>
                                            </div>
                                            <div className='table-medical-conditions__tr'>
                                                <div className='table-medical-conditions__td'>2</div>
                                                <Link to={{ pathname: '/Detail-page', state: { title: "Nausea and Vomiting of Pregnancy (NVP)" } }}>
                                                    <div className='table-medical-conditions__td' style={{ width: "100%" }}>Nausea and Vomiting of Pregnancy (NVP)</div>
                                                </Link>
                                            </div>
                                            <div className='table-medical-conditions__tr'>
                                                <div className='table-medical-conditions__td'>3</div>
                                                <Link to={{
                                                    pathname: '/Detail-page', state: {
                                                        title: "Diabetes and Gestational Diabetes" } }}>
                                                    <div className='table-medical-conditions__td' style={{ width: "100%" }}>Diabetes and Gestational Diabetes</div>
                                                </Link>
                                            </div>
                                            <div className='table-medical-conditions__tr'>
                                                <div className='table-medical-conditions__td'>4</div>
                                                <Link to={{
                                                    pathname: '/Detail-page', state: {
                                                        title: "Depression" } }}>
                                                    <div className='table-medical-conditions__td' style={{ width: "100%" }}>Depression</div>
                                                </Link>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                        </div>
                    </div>
                </section>
            </BaseLayout>
        );
    }
}

export default MedicalConditionsPub;



