import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import { Breadcrumb, Modal, Icon } from 'semantic-ui-react';
import ImgVideo from '../images/img-video.jpg';
import entisLogo from '../images/entis-logo.jpg';

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const trigger = (
            <div className='about-us__img-video'>
                <img src={ImgVideo} alt='' />
            </div>
        );
        const sections = [
            { key: 'Home', content: 'Home', link: true },
            { key: 'About Us', content: 'About Us', active: true },
        ];
        return (
            <BaseLayout>
                <section className='breadcrumbs'>
                    <div className='container'>
                        <Breadcrumb icon='right angle' sections={sections} />
                    </div>
                </section>
                <section className='about-us'>
                    <div className='container'>
                        <div className='about-us__content'>
                            <div className='about-us__ttl'>About us</div>
                            <div className='about-us__text'>
                                Motherhood should not be a fight. In today&apos;s context, making sure that pregnant and breastfeeding women have up to date and high-quality safety information about the medicines they use, built on science, is more important than ever before.<br /><br />
                                A knowledge bank, containing information on the safety of using different medicines during pregnancy and breastfeeding, has been developed as part of the  <a href="https://synergist.acemlna.com/lt.php?s=18790ff4c5ad09fdda28dcfe879755df&i=247A498A13A1815" target="_blank">IMI ConcePTION</a>, an EU-funded collaborative project. 
                                <br /><br />
                                Our vision is that the information in the knowledge bank will aid women and healthcare professionals to make clear, informed decisions on whether or not a pregnant or breastfeeding woman can take medicines during pregnancy. 
                                <br /><br /><br /><br />
                             </div>
                        </div>
                        <div className='about-contact'>
                            <div className='about-contact__ttl'>Contact information</div>
                            <div className='about-contact__content'>
                                <div className='about-contact__left'>
                                    <div className='about-contact__sub-ttl'>
                                        Teratology Information Services Organisations
                  </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                  </p>
                                    <a href='/' className='about-contact__link'>
                                        <Icon name='linkify'></Icon>
                    entis-org.eu
                  </a>
                                </div>
                                <div className='about-contact__right'>
                                    <img src={entisLogo} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BaseLayout>
        );
    }
}

export default AboutUs;
