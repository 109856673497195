import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import { Breadcrumb, Modal, Icon } from 'semantic-ui-react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

class InFocusDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {

        const sections = [
            { key: 'Home', content: 'Home', link: true },
            { key: 'In focus', content: 'In focus', active: true }
        ];

        return (
            <BaseLayout>
                <section className='breadcrumbs'>
                    <div className='container'>
                        <Breadcrumb icon='right angle' sections={sections} />
                    </div>
                </section>
                <section className='about-us'>
                    <div className='container'>
                        <div className='about-us__content'>
                            <div className='about-us__ttl'>{this.props.location.state.title}</div>
                                <div className='about-us__text'>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.location.state.details }} />
                                    <br /><br />
                                </div>
                        </div>
                    </div>
                    <div className='maternal-conditions__btn'>
                       <Button onClick={this.goBack} color='lightblue'>Back</Button>
                    </div>
                    <br /><br />
                </section>
            </BaseLayout>
        );
    }
}

export default InFocusDetail;

