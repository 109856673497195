import React from 'react';
import SearchBar from './SearchBar';

function MainSearch() {
  return (
    <section className='main-search'>
      <div className='container'>
        <div className='main-search__content'>
          <h1 className='main-search__ttl'>Search for knowledge pages</h1>
          <div className='main-search__search'>
            <SearchBar buttonColor='darkBlue' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSearch;
