import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

class BaseLayout extends React.Component {
  render() {
    return (
        <div className='wrapper'>
            <div style={{ width: "100%", textAlign: "center", color: "white", backgroundColor: "red", padding: "15px" }}><b>Important:</b> This website is currently in development and is not representative of the final version of the knowledge bank. <br/>All information shown is for demonstration purposes only and should not be used to make decisions about using medicines during pregnancy or breastfeeding.</div>
        <div className='content'>
          <Header headerWithShadow={this.props.headerWithShadow} />
          {this.props.children}
        </div>
        <Footer />
      </div>
    );
  }
}

export default BaseLayout;
