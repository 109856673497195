import React from 'react';

class References extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
    //console.log("test", props.referenceid);

  }
  toggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }

  render() {
    let style = 'references__action';
    if (this.state.isOpen) {
      style = 'references__action active';
    }
      return (
      
      <div className='references'>
        <div className='references__head'>
                <div className='references__ttl'>References</div>
          <div className={style}>
            <button onClick={this.toggle}>Show</button>
          </div>
            </div>
            {this.state.isOpen && this.props.referenceid == "Azithromycin" && this.props.referencetype == "pregnancy" &&  (
            <div className='references__body'>
            <ul className='references__list'>
                <li className='references__item'>
                        1.	Fan H, Gilbert R, O&apos;Callaghan F, Li L. Associations between macrolide antibiotics prescribing during pregnancy and adverse child outcomes in the UK: population based cohort study. BMJ. 2020 Feb; 368: m331.
                 </li>
                <li className='references__item'>
                        2.	Mallah N, Tohidinik HR, Etminan M, Figueiras A, Takkouche B. Prenatal Exposure to Macrolides and Risk of Congenital Malformations: A Meta - Analysis. Drug Saf. 2020 Mar; 43(3): 211&#45;21.
                </li>
                    <li className='references__item'>
                        3.	Fan H, Li L, Wijlaars L, Gilbert RE. Associations between use of macrolide antibiotics during pregnancy and adverse child outcomes: A systematic review and meta - analysis. PLoS One. 2019; 14(2): e0212212.
                </li>
                    <li className='references__item'>
                        4.	Meeraus WH, Petersen I, Gilbert R. Association between antibiotic prescribing in pregnancy and cerebral palsy or epilepsy in children born at term: a cohort study using the health improvement network. PLoS One [Internet]. 2015; 10(3): e0122034.
                </li>
                    <li className='references__item'>
                        5.	Muanda FT, Sheehy O, Berard A. Use of antibiotics during pregnancy and risk of spontaneous abortion. CMAJ [Internet]. 2017; 189(17): E625&#45;33.
                </li>
                    <li className='references__item'>
                        6.	Muanda FT, Sheehy O, Berard A. Use of antibiotics during pregnancy and the risk of major congenital malformations: a population based cohort study. Br J Clin Pharmacol. 2017 Nov; 83(11): 2557&#45;71.
                </li>
                    <li className='references__item'>
                        7.	Sarkar M, Woodland C, Koren G, Einarson AR. Pregnancy outcome following gestational exposure to azithromycin. BMC Pregnancy Childbirth [Internet]. 2006; 6: 18.
                </li>
                    <li className='references__item'>
                        8.	Bar - Oz B, Weber - Schoendorfer C, Berlin M, Clementi M, Di Gianantonio E, de Vries L, et al. The outcomes of pregnancy in women exposed to the new macrolides in the first trimester: a prospective, multicentre, observational study. Drug Saf [Internet]. 2012; 35(7): 589&#45;98.
                </li>
                    <li className='references__item'>
                        9.	Bar - Oz B, Diav - Citrin O, Shechtman S, Tellem R, Arnon J, Francetic I, et al. Pregnancy outcome after gestational exposure to the new macrolides: a prospective multi - center observational study.Eur J Obs Gynecol Reprod Biol [Internet]. 2008; 141(1): 31&#45;4.
                </li>
                    <li className='references__item'>
                        10.	Cooper WO, Hernandez - Diaz S, Arbogast PG, Dudley JA, Dyer SM, Gideon PS, et al. Antibiotics potentially used in response to bioterrorism and the risk of major congenital malformations. Paediatr Perinat Epidemiol [Internet]. 2009 / 02 / 21. 2009; 23(1): 18&#45;28.
                </li>
                    <li className='references__item'>
                        11.	Berard A, Sheehy O, Zhao JP, Nordeng H. Use of macrolides during pregnancy and the risk of birth defects: a population - based study. Pharmacoepidemiol Drug Saf [Internet]. 2015; 24(12): 1241&#45;8.
                </li>
                    <li className='references__item'>
                        12.	Damkier P, Bronniche LMS, Korch - Frandsen JFB, Broe A. In utero exposure to antibiotics and risk of congenital malformations: a population - based study. Am J Obstet Gynecol. 2019 Dec; 221(6): 648.e1 - 648.e15. 
                </li>
                </ul>
                </div>
            )}
            {this.state.isOpen && this.props.referenceid == "Azithromycin" && this.props.referencetype == "breastfeeding" && (
                <div className='references__body'>
                <ul className='references__list'>
                    <li className='references__item'>
                        1.	Sutton AL, Acosta EP, Larson KB et al. Perinatal pharmacokinetics of azithromycin for cesarean prophylaxis. Am J Obstet Gynecol. 2015;212:812.e1&#45;6.
                 </li>
                    <li className='references__item'>
                        2.	Salman S, Davis TM, Page-Sharp M et al. Pharmacokinetics of transfer of azithromycin into the breast milk of African mothers. Antimicrob Agents Chemother. 2016;60:1592&#45;9.
                </li>
                    <li className='references__item'>
                        3.	Goldstein LH, Berlin M, Tsur L et al. The safety of macrolides during lactation. Breastfeed Med. 2009;4:197&#45;200.
                </li>
                    <li className='references__item'>
                        4.	Sorensen HT, Skriver MV, Pedersen L et al. Risk of infantile hypertrophic pyloric stenosis after maternal postnatal use of macrolides. Scand J Infect Dis. 2003;35:104&#45;6.
                </li>
                    <li className='references__item'>
                        5.	Lund M, Pasternak B, Davidsen RB et al. Use of macrolides in mother and child and risk of infantile hypertrophic pyloric stenosis: Nationwide cohort study. BMJ. 2014;348:g1908
                </li>
                    <li className='references__item'>
                        6.	Abdellatif M, Ghozy S, Kamel MG et al. Association between exposure to macrolides and the development of infantile hypertrophic pyloric stenosis: a systematic review and meta-analysis. Eur J Pediatr. 2019;178:301&#45;14.
                </li>
                    <li className='references__item'>
                        7.	Almaramhy HH, Al-Zalabani AH. The association of prenatal and postnatal macrolide exposure with subsequent development of infantile hypertrophic pyloric stenosis: A systematic review and meta-analysis. Ital J Pediatr. 2019;45:20. [PMC free article] [PubMed]
                </li>
                </ul>
                </div>
            )}
            {this.state.isOpen && this.props.referenceid == "Tocilizumab" && this.props.referencetype == "pregnancy" && (
                <div className='references__body'>
                    <ul className='references__list'>
                        <li className='references__item'>
                            1.	Tada Y, Sakai M, Nakao Y, Maruyama A, Ono N, Koarada S. Placental transfer of tocilizumab in a patient with rheumatoid arthritis. Vol. 58, Rheumatology (Oxford, England). England; 2019. p. 1694&#45;5
                 </li>
                        <li className='references__item'>
                            2.	Roche Products Limited. SmPC: RoActemra 162 mg Solution for Injection in Pre-Filled Pen. 2020.
                </li>
                        <li className='references__item'>
                            3.	Rubbert-Roth A, Goupille P, Moosavi S, Hou A. First experiences with pregnancies in RA patients receiving trastuzumab therapy [Abstract]. Arthritis Rheum. 2010;62:S161.
                </li>
                        <li className='references__item'>
                            4.	Ishikawa I, Hirano Y, Kaneko A, Kida D, Sato T, Kojima T, et al. Pregnancy in patients with rheumatoid arthritis treated with biological agents: results of the 8-year of Japanese TBC registry. Ann Rheum Dis. 2012;71(Suppl 3):501.
                </li>
                        <li className='references__item'>
                            5.	Gotestam Skorpen C, Hoeltzenbein M, Tincani A, Fischer-Betz R, Elefant E, Chambers C, et al. The EULAR points to consider for use of antirheumatic drugs before pregnancy, and during pregnancy and lactation. Ann Rheum Dis. 2016 May;75(5):795&#45;810.
                </li>
                        <li className='references__item'>
                            6.	Weber-Schoendorfer C, Schaefer C. Pregnancy outcome after tocilizumab therapy in early pregnancy-a case series from the German Embryotox Pharmacovigilance Center. Reprod Toxicol. 2016 Apr;60:29&#45;32.
                </li>
                        <li className='references__item'>
                            7.	Hoeltzenbein M, Beck E, Rajwanshi R, Gotestam Skorpen C, Berber E, Schaefer C, et al. Tocilizumab use in pregnancy: Analysis of a global safety database including data from clinical trials and post-marketing data. Semin Arthritis Rheum. 2016 Oct;46(2):238&#45;45.
                </li>
                        <li className='references__item'>
                            8.	Kaneko K, Sugitani M, Goto M, Murashima A. Tocilizumab and pregnancy: Four cases of pregnancy in young women with rheumatoid arthritis refractory to anti-TNF biologics with exposure to tocilizumab. Mod Rheumatol. 2016 Sep;26(5):672&#45;5.
                </li>
                        <li className='references__item'>
                            9.	Nakajima K, Watanabe O, Mochizuki M, Nakasone A, Ishizuka N, Murashima A. Pregnancy outcomes after exposure to tocilizumab: A retrospective analysis of 61  patients in Japan. Mod Rheumatol. 2016 Sep;26(5):667&#45;71.
                </li>
                        <li className='references__item'>
                            10.	Public Health England. Contraindications and special considerations: the green book, chapter 6 [Internet]. 2017. Available from: https://www.gov.uk/government/publications/contraindications-and-special-considerations-the-green-book-chapter-6 
                </li>
                        <li className='references__item'>
                            11.	15.  MHRA. Updates to Public Health England's Green Book chapter on live attenuated vaccines. Drug Saf Updat. 2017;11(4):6. 
                </li>
                    </ul>
                </div>
            )}
            {this.state.isOpen && this.props.referenceid == "Tocilizumab" && this.props.referencetype == "breastfeeding" && (
                <div className='references__body'>
                    <ul className='references__list'>
                        <li className='references__item'>
                            1.	Saito J, Yakuwa N, Takai C, et al. Tocilizumab concentrations in maternal serum and breast milk during breastfeeding and a safety assessment in infants: A case study. Rheumatology (Oxford). 2018;57:1499&#45;501.
                 </li>
                        <li className='references__item'>
                            2.	Saito J, Yakuwa N, Kaneko K, et al. Tocilizumab during pregnancy and lactation: Drug levels in maternal serum, cord blood, breast milk and infant serum. Rheumatology (Oxford). 2019;58:1505&#45;7.
                </li>
                        <li className='references__item'>
                            3.	Saito J, Yakuwa N, Kaneko K, et al. Clinical application of the dried milk spot method for measuring tocilizumab concentrations in the breast milk of patients with rheumatoid arthritis. Int J Rheum Dis. 2019;22:1130&#45;7.
                </li>
                        <li className='references__item'>
                            4.	Nakajima K, Watanabe O, Mochizuki M, et al. Pregnancy outcomes after exposure to tocilizumab: A retrospective analysis of 61 patients in Japan. Mod Rheumatol. 2016;26:667&#45;71
                </li>
               </ul>
                </div>
              )}
              {this.state.isOpen && (this.props.referenceid == "Rheumatoid Arthritis" || this.props.referenceid == "Nausea and Vomiting of Pregnancy (NVP)" || this.props.referenceid == "Diabetes and Gestational Diabetes" || this.props.referenceid == "Depression") && (
                  <div className='references__body'>
                      <ul className='references__list'>
                          <li className='references__item'>
                              1.	Demo J, Yakuwa N, Takai C, et al. Tocilizumab concentrations in maternal serum and breast milk during breastfeeding and a safety assessment in infants: A case study. Rheumatology (Oxford). 2018;57:1499&#45;501.
                 </li>
                          <li className='references__item'>
                              2.	Demo J, Yakuwa N, Kaneko K, et al. Tocilizumab during pregnancy and lactation: Drug levels in maternal serum, cord blood, breast milk and infant serum. Rheumatology (Oxford). 2019;58:1505&#45;7.
                </li>
                          <li className='references__item'>
                              3.	Demo J, Yakuwa N, Kaneko K, et al. Clinical application of the dried milk spot method for measuring tocilizumab concentrations in the breast milk of patients with rheumatoid arthritis. Int J Rheum Dis. 2019;22:1130&#45;7.
                </li>
                          <li className='references__item'>
                              4.	Demo K, Watanabe O, Mochizuki M, et al. Pregnancy outcomes after exposure to tocilizumab: A retrospective analysis of 61 patients in Japan. Mod Rheumatol. 2016;26:667&#45;71
                </li>
                      </ul>
                  </div>
              )}
      </div>
    );
  }
}

export default References;














