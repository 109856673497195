import React from 'react';
import { BrowserRouter as Router, NavLink, Link } from 'react-router-dom';
import Logo from '../images/mums.png';
import Hamburger from '../images/ico-hamburger.svg';
import IcoClose from '../images/ico-close-popup.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpenMobileMenu: false };
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
  }
  openMobileMenu() {
    this.setState((state) => ({
      isOpenMobileMenu: true,
    }));
    document.body.style.overflow = 'hidden';
  }
  closeMobileMenu() {
    this.setState((state) => ({
      isOpenMobileMenu: false,
    }));
    document.body.style.overflow = 'auto';
  }
  render() {
    const { isOpenMobileMenu } = this.state;
    let className = 'header';
    if (this.props.headerWithShadow) {
      className = 'header header--shadow';
    }
    return (
      <header className={className}>
        {isOpenMobileMenu && (
          <div className='mobile-menu'>
            <button
              className='mobile-menu__close'
              onClick={this.closeMobileMenu}
            >
              <img src={IcoClose} alt='' />
            </button>
            <ul className='mobile-menu__list'>
              <li>
                <a href='/home'>Home</a>
              </li>
              <li>
                <a href='/about-us'>About us</a>
              </li>
              <li>
                <a href='/how-we-work'>How we work</a>
              </li>
            </ul>
          </div>
        )}
        <div className='container'>
          <div className='header__row'>
            <div className='header__left'>
               <Link to='/' className='header__logo'>
                 <img src={Logo} alt='' width='250' />
               </Link>
            </div>
            <div className='header__right'>
                <nav className='main-nav'>
                  <ul className='main-nav__list'>
                    <li className='main-nav__item'>
                      <NavLink className='main-nav__link' to='/home'>
                        Home
                      </NavLink>
                    </li>
                    <li className='main-nav__item'>
                      <NavLink className='main-nav__link' to='/about-us'>
                        About us
                      </NavLink>
                    </li>
                    <li className='main-nav__item'>
                      <NavLink className='main-nav__link' to='/how-we-work'>
                        How we work
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              <button
                className='header__hamburger'
                onClick={this.openMobileMenu}
              >
                <img src={Hamburger} alt='' />
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
