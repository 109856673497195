import React from 'react';

class DrugListItem extends React.Component {
  render() {
    const { num } = this.props;
    return (
      <div className='table-drug-list__tr'>
        <div className='table-drug-list__td'>{num}</div>
        <div className='table-drug-list__td'>
          <div className='table-drug-list__mobile-ttl'>Substance</div>
          Paracetamol
        </div>
        <div className='table-drug-list__td'>
          <div className='table-drug-list__mobile-ttl'>Brandname</div>
          Mylan
        </div>
        <div className='table-drug-list__td'>
          <div className='table-drug-list__mobile-ttl'>ATC code</div>
          NO2BE17
        </div>
        <div className='table-drug-list__td'>
          <div className='table-drug-list__mobile-ttl'>Country</div>
          The Netherlands
        </div>
      </div>
    );
  }
}

export default DrugListItem;
