import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import SearchBar from '../components/SearchBar';
import { Breadcrumb, Transition } from 'semantic-ui-react';
import ImgNews from '../images/img-sidebar-news.jpg';
import References from '../components/References';
import ChooseTranslation from '../components/ChooseTranslation';


class DetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMore1: false, showMore2: false };
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);

    this.prgRef = React.createRef();
    this.bfRef = React.createRef();

    this.prgNodeClass = 'page-navigation__link page-navigation__link--active';
    this.bgNodeClass = 'page-navigation__link';

    this.scrollToMyRef = this.scrollToMyRef.bind(this);
  }

    scrollToMyRef = (opt) => {
        switch (opt) {
            case "prg":
                console.log("opt: ", opt);
                this.prgNodeClass = 'page-navigation__link page-navigation__link--active';
                this.bgNodeClass = 'page-navigation__link';
                window.scrollTo({ behavior: 'smooth', top: this.prgRef.current.offsetTop });
                break;
            case "bf":
                console.log("opt: ", opt);
                this.prgNodeClass = 'page-navigation__link';
                this.bgNodeClass = 'page-navigation__link page-navigation__link--active';
                window.scrollTo({ behavior: 'smooth', top: this.bfRef.current.offsetTop });
                break;
            default:

                break;
        }

        this.setState({ state: this.state });
    }

  componentDidMount() {
    const script = document.createElement('script');

    script.src =
      'https://platform-api.sharethis.com/js/sharethis.js#property=5ee868d4a20a730012afe41b&product=sticky-share-buttons';
    script.async = true;

      document.body.appendChild(script);
      window.scrollTo(0, 0);
  }
  toggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      showMore1: !state.showMore1,
    }));
  }
  toggle2(e) {
    e.preventDefault();
    this.setState((state) => ({
      showMore2: !state.showMore2,
    }));
  }

    setKMScontent() {
        let KMScontent = {
            showBreastFeeding: true,
            title: 'Knowledge page title',
            pregnancysummary: 'Summary information',
            pregnancydetailsshort: 'Details short',
            pregnancydetails: 'Detailed information',
            breastfeedingsummary: 'Summary information',
            breastfeedingdetailsshort: 'Details short',
            breastfeedingdetails: 'Detailed information',
        }

        if (this.props.location.state.title == 'Rheumatoid Arthritis') {
            KMScontent.showBreastFeeding = false;
            KMScontent.title = 'Rheumatoid Arthritis';
            KMScontent.pregnancysummary = 'Rheumatoid arthritis (RA) is a kind of arthritis that mainly affects your joints. It is an autoimmune condition, meaning the immune system doesn&apos;t work the way it should, causing inflammation (swelling) in the joints. Symptoms can include swelling, stiffness, discomfort, pain, and may cause you to have limited movement. Many people with RA might also have some symptoms that don&apos;t involve the joints like fatigue and low-grade fevers (temperature above normal, but below 100.4). <br/><br/>';
            KMScontent.pregnancydetailsshort = 'Women with RA have a higher chance for problems during pregnancy.';
            KMScontent.pregnancydetails = 'If the RA is well controlled for 3-6 months before becoming pregnant, the chance for pregnancy complications is usually lower than in women with more active RA. Since most people with RA take medication to control the inflammation and prevent or reduce joint damage, it is important to talk with your healthcare providers about your medications and the best treatment options before trying to get pregnant.';
            KMScontent.breastfeedingsummary = '';
            KMScontent.breastfeedingdetailsshort = '';
            KMScontent.breastfeedingdetails = '';

        }
        if (this.props.location.state.title == 'Nausea and Vomiting of Pregnancy (NVP)') {
            KMScontent.showBreastFeeding = false;
            KMScontent.title = 'Nausea and Vomiting of Pregnancy (NVP)';
            KMScontent.pregnancysummary = 'VP or morning sickness is the most common medical condition in pregnancy. It affects up to 80% of pregnant women. NVP symptoms can range from mild to severe. The symptoms can happen at any time during the day or at night. Symptoms can include nausea, dry heaves, retching and/or vomiting. NVP usually begins between 4-9 weeks of pregnancy, and peaks between 7-12 weeks. In most women, symptoms go away between 12-16 weeks of pregnancy. Up to 15% of women will continue to have symptoms up to 20 weeks of pregnancy or until delivery.</br></br>';
            KMScontent.pregnancydetailsshort = 'In every pregnancy, a woman starts with a 3-5% chance of having a baby with a birth defect. This is called her background risk. Most studies have not shown an increased chance for birth defects above the background risk when a women has nausea and vomiting during pregnancy.</br></br>';
            KMScontent.pregnancydetails = 'Women with severe NVP or those who have HG are at a greater risk of getting dehydrated, as they might not able to drink enough water. This can cause medical complications for the mother. Some studies have found that HG might be associated with a slightly higher chance of having a baby that is smaller than expected.';
            KMScontent.breastfeedingsummary = '';
            KMScontent.breastfeedingdetailsshort = '';
            KMScontent.breastfeedingdetails = '';
        }

        if (this.props.location.state.title == 'Diabetes and Gestational Diabetes') {
            KMScontent.showBreastFeeding = false;
            KMScontent.title = 'Diabetes and Gestational Diabetes';
            KMScontent.pregnancysummary = 'Diabetes is a medical condition in which the body either does not make enough insulin or cannot use insulin correctly. Insulin is a hormone in the blood that is necessary for providing our cells with energy to function. Insulin helps sugar (glucose) move from the bloodstream into the cells. When glucose cannot enter our cells, it builds up in the blood (hyperglycemia). These high sugar levels can lead to damage of organs like the eyes and kidneys, and damage blood vessels and nerves.</br /> </br>There are different classes of diabetes.Some people have Type 2 diabetes(once called adult onset diabetes).This means that the body does not produce enough insulin or the insulin is not able to work well.In contrast, people with Type 1 diabetes(once called juvenile - onset diabetes or insulin - dependent diabetes) have a condition where the body does not produce any insulin at all. People with Type 1 diabetes need insulin injections and close monitoring to control their blood sugar levels.<br/><br/>';
            KMScontent.pregnancydetailsshort = 'It is recommended that you speak with your health care providers before becoming pregnant. This will help determine the best plan of care to keep your blood glucose levels under control before and during pregnancy. A blood test called hemoglobin A1c (glycosated hemoglobin) can be done to estimate glucose control over the past 2-3 months. Ideally, this level should be within the normal range before pregnancy. Some health care providers will recommend additional home blood glucose testing to check the sugar levels more often during pregnancy.</br ></br >Women with diabetes should also work with their health care providers and nutritionists to develop a personalized diet and exercise plan before pregnancy.<br/><br/>';
            KMScontent.pregnancydetails = 'Most babies born to women with diabetes are not born with birth defects. Studies have shown that women who have well-controlled diabetes before becoming pregnant and who also maintain low glucose levels throughout the pregnancy do not have an increased chance for having a baby born with birth defects.</br ></br >However, high glucose levels during pregnancy increase the chance that a baby will be born with birth defects.High glucose levels have the greatest effect early in pregnancy, possibly before a woman knows she is pregnant.For pregnant women with poor diabetic control, the chance for a baby to be born with birth defects is about 6 - 10 %.For those with extremely poor control in the first trimester, there may be up to a 20 % chance for birth defects.Some of the associated birth defects include spinal cord defects(spina bifida), heart defects, skeletal defects, and defects in the urinary, reproductive, and digestive systems.';
            KMScontent.breastfeedingsummary = '';
            KMScontent.breastfeedingdetailsshort = '';
            KMScontent.breastfeedingdetails = '';
        }

        if (this.props.location.state.title == 'Depression') {
            KMScontent.showBreastFeeding = false;
            KMScontent.title = 'Depression';
            KMScontent.pregnancysummary = 'Depression is a serious medical illness. It can change how someone feels, thinks and acts. The most common' +
                'symptoms of depression are long - lasting and strong feelings of sadness and not being able to feel pleasure or happiness. ' +
            'Other symptoms of depression are anxiety, irritability, difficulty concentrating, fatigue(feeling very tired), and ' +
                'thoughts of death or self - harm.Physical symptoms of depression can include increased heart rate, loss of appetite, ' +
                'stomach pain, and headaches.< br /> <br />' +
                'The chance for a woman to develop depression during her lifetime is about 10 - 25 %.The highest risk occurs ' +
                'during the childbearing years.Pregnancy may be a possible trigger for the development of depression in some women. ' +
                'This may be due to changes in hormone levels during pregnancy and the stress that comes with this major life event. ' +
            'Treatment for depression usually includes counseling / psychotherapy and / or medications. </br ></br >';
            KMScontent.pregnancydetailsshort = 'Talk to your healthcare providers about your desire to become pregnant. This will allow your healthcare providers, including your counselors / therapists to review your current health plan.If your healthcare provider has a question about the effect of the medications on pregnancy, talk to a teratogen information specialist.Sometimes, changes in treatment may be recommended before pregnancy or during pregnancy.</br ></br >';
            KMScontent.pregnancydetails = 'Many antidepressant medications have been studied in pregnancy and have not been linked to a higher chance '+
                'for birth defects.Studies in some antidepressants have shown that when taken during the third trimester, there may be ' +
                'effects in the newborn after birth.The baby may be jittery, irritable, and / or have difficulties with feeding, sleeping, ' +
                'breathing and heart rate.In most cases, these newborn symptoms last a few days or less.Taking more than one ' +
                'medication may increase the chance for these side effects.Not every baby would be affected.You can contact ' +
            'MotherToBaby to learn if there are studies on the medication you are taking.';
            KMScontent.breastfeedingsummary = '';
            KMScontent.breastfeedingdetailsshort = '';
            KMScontent.breastfeedingdetails = '';
        }

        if (this.props.location.state.title == 'Azithromycin') {
            KMScontent.showBreastFeeding = true;
            KMScontent.title = 'Azithromycin';
            KMScontent.pregnancysummary = 'Azithromycin can be used in pregnancy if there is an infection which could affect the health of the mother or the baby. There is quite a lot of information about azithromycin use during pregnancy. Most studies have not described any unwanted effects on the baby. Single studies have indicated small increased risks of miscarriage and birth defects. However, these findings are possibly due to the way the research studies were done. Other studies have not found the same risks.<br/><br/>';
            KMScontent.pregnancydetailsshort = 'Maternal infection during pregnancy can be hazardous to both the mother and the unborn fetus. Adequate treatment of maternal infections which arise during pregnancy is therefore essential. The choice of antibiotic in pregnancy should generally be guided by local prescribing guidelines and the results of culture and sensitivity tests.<br/><br/>';
            KMScontent.pregnancydetails = 'Several large studies have investigated the risk of adverse pregnancy/fetal outcomes for both macrolide antibiotics as a class and for other specific macrolides. Together these studies describe in excess of 30,000 macrolide exposed pregnancies (>6,000 in the first trimester). Although the majority of these studies have not described associations with adverse outcomes, some individual studies have described small increased risks of overall malformation [1] [2], specific anomalies including cardiac [1], genital [1], musculoskeletal [2] and gastrointestinal defects [3], miscarriage and cerebral palsy and/or epilepsy [4]. However, the evidence for these associations is conflicting, and studies were often limited by methodological biases and/or data confounding. ' +
                '<br/><br/>Evidence relating to azithromycin specifically is provided from nine controlled studies which together describe more than 7,800 unique exposed pregnancies with approximately 6,700 exposed in the first trimester. ' +
                '<br/><br/>The majority of studies investigating azithromycin use in pregnancy have not described adverse fetal outcomes. Individual studies have described small increased risks of miscarriage5 and overall malformation [6] following azithromycin exposure in pregnancy. However, methodological biases and/or data confounding likely influenced the study results, and other studies investigating these outcomes have not confirmed the findings [7-12]. Five studies have investigated cardiac malformation risks specifically with none providing evidence of an association with fetal azithromycin exposure [5] [8] [10] [11] [12]. ' +
                '<br/><br/>Two small studies did not describe a statistically significant increased risk of preterm delivery following maternal azithromycin use in pregnancy [7] [13], and one small study described no statistically significant difference in mean birth weight between azithromycin exposed and unexposed comparator groups [7]. ' +
                '<br/><br/>No studies have been located which have investigated the risk of intrauterine death, neurodevelopmental impairment or neonatal / infant complications following maternal azithromycin use in pregnancy. ' +
                '<br/><br/>In conclusion, the available evidence as a whole does not currently indicate that azithromycin use should be avoided in pregnancy. As a precautionary approach, azithromycin should be reserved for compelling indications where there are no suitable alternatives with more adequate pregnancy safety data available.<br/><br/>';
            KMScontent.breastfeedingsummary = 'Azithromycin can be used by breastfeeding mothers where required. Only very small amounts of azithromycin is found in breast milk and this is not expected to have any unwanted effects on the baby.  There is also some information available about azithromycin use during breast feeding which supports the safety for the baby. ' + 
            '<br/><br/>Some studies have suggested that antibiotics which work in the same way as azithromycin (collectively called macrolide antibiotics) could increase the risk of a condition called infantile hypertrophic pyloric stenosis. Mothers that are breastfeeding whilst using azithromycin may be asked to monitor their child for symptoms of projectile vomiting, poor feeding, weight loss and dehydration (fewer wet nappies than expected).' +
            '<br/><br/>There is also a possibility that any antibiotic which reaches the baby through the breastmilk could affect the natural bacteria in the baby’s gut. Mothers that are breastfeeding whilst using azithromycin should closely monitor their infant for signs of diarrhoea, vomiting and rash.<br/><br/>';
            KMScontent.breastfeedingdetailsshort = 'Maternal infections which arise in breastfeeding patients should be treated adequately. ' +
                '<br/><br/>Published experience of azithromycin exposure during breastfeeding consists of a small number of small case series and pharmacokinetic studies which together describe the outcomes of approximately 50 azithromycin exposed mother - infant pairs.<br/><br/>';
            KMScontent.breastfeedingdetails = 'No data has been located concerning the impact of azithromycin use on the ability to breastfeed, but there are numerous reports of successful breastfeeding by exposed patients.' +
            '<br/><br/>Expected relative infant doses of azithromycin in the breastmilk have been estimated to be between 0.1 and 1.2 mg/kg/day [1] [2] in comparison with standard paediatric doses of azithromycin (5 to 10 mg/kg).' +
                '<br/><br/>Two small studies do not provide evidence of an increased risk of infant adverse effects following exposure to azithromycin through breastmilk [1] [3]. Concerns have been raised that macrolide use during breastfeeding may increase the risk of infantile hypertrophic pyloric stenosis, particularly in the first 13 days post&#45;partum [4] [5]. However, the results of meta&#45analyses have not confirmed this association [6] [7]. ' +
            '<br/><br/>Considering both the available pharmacokinetic data, and data from studies investigating infant outcomes following exposure to azithromycin through breastmilk, azithromycin can be used where indicated by breastfeeding patients. Due to conflicting information regarding infantile hypertrophic pyloric stenosis risks, some resources recommend monitoring infants for symptoms when they have been exposed to macrolide antibiotics through breastmilk.' +
            '<br/><br/>As with any antibiotic, there is a possibility that exposure to azithromycin through the breastmilk could affect the infant gut flora. No evidence of any clinically significant effects have been described for azithromycin, but parental monitoring for symptoms of diarrhoea, vomiting and/or rash are generally recommended when using antibiotics whilst breastfeeding.<br/><br/>';
        }

        if (this.props.location.state.title == 'Tocilizumab') {
            KMScontent.showBreastFeeding = true;
            KMScontent.title = 'Tocilizumab';
            KMScontent.pregnancysummary = 'In some patients, the benefits of using tocilizumab in pregnancy may outweigh the potential risks. Unfortunately, most pregnant patients who reported using this medication stopped it early in their pregnancy. Therefore, not a lot is known about whether this medication is safe to use in pregnancy. From what is known, it does not appear that Tocilizumab use in early pregnancy increases the risk of birth defects or miscarriage. We do not know enough about how Tocilizumab use later in pregnancy can affect the baby. Using medications like tocilizumab in pregnancy could affect the baby&apos;s immune system and increase their risk of infection after they are born. There are currently no known cases of this occurring after tocilizumab has been used in pregnancy. To protect infants from infection, vaccines containing live viruses or bacteria might not be given when women have used tocilizumab during pregnancy.<br/><br/>';
            KMScontent.pregnancydetailsshort = 'Tocilizumab is an immunosuppressant monoclonal antibody of the IgG1 subclass. Transplacental transfer of IgG antibodies has been demonstrated from as early as 8 to 10 weeks gestational age, and increases throughout pregnancy. When tocilizumab is used until delivery, tocilizumab will be present in the blood of the newborn [1].<br/><br/> ';
            KMScontent.pregnancydetails = 'Reproductive toxicity testing conducted by the manufacturer of tocilizumab does not suggest harmful effects on the developing fetus [2].' +
            '<br/><br/>There are currently no controlled studies of tocilizumab use in human pregnancy, with published evidence consisting of a small number of uncontrolled case reports / series which together describe at least 360 unique exposed pregnancies, the majority of which were only exposed in very early pregnancy [3&#45;9].' +
                '<br/><br/>The available data do not provide compelling evidence that tocilizumab increases the risk of malformation or spontaneous abortion, but are currently too limited to provide reliable conclusions.' +
                '<br/><br/>The available data do not currently indicate that tocilizumab use increases the risk of intrauterine death, nor do they provide compelling evidence of an increased risk of fetal growth impairment, preterm delivery or neonatal / infant complications. However, due to the high rates of tocilizumab discontinuation in early pregnancy, these data are currently insufficient to assess the safety of tocilizumab use in the later stages of pregnancy.' +
            '<br/><br/>No data were located regarding infant neurodevelopmental outcomes following <i>in utero</i> tocilizumab exposure.' +
            '<br/><br/>Exposure to biologic immunosuppressant monoclonal antibodies in pregnancy could theoretically result in neonatal / infant immunosuppression and an increased risk of infection. There are currently limited data regarding neonatal / infant immune function following in <i>utero</i> tocilizumab exposure. Owing to theoretical concerns, where appropriate, live vaccines should be deferred in accordance with local guidelines following <i>in utero</i> biologic immunosuppressant exposure [10] [11].' +
            '<br/><br/>In some patients, the benefits of using tocilizumab in pregnancy may outweigh the potential risks. However, the available evidence is currently highly limited, making quantification of these risks difficult. The decision to use or continue tocilizumab in pregnancy should be undertaken jointly by the patient, prescribing clinician and obstetrician after careful discussion and consideration of the available evidence and its limitations.<br/><br/>';
            KMScontent.breastfeedingsummary = 'It does not appear that tocilizumab use by breastfeeding mothers has any unwanted effects on the health or development of the baby. However, information is only available for a small number of infants who were breastfed by women receiving this medication. ' +
            '<br/><br/>From what is known, tocilizumab can be found in breastmilk, but it is possible that only very small amounts may reach the breastfed baby. The small amounts which may reach the infant through breastmilk is also likely to be broken down in the infant’s stomach. Using medications like tocilizumab whilst breastfeeding could affect the baby’s immune system and increase their risk of infection. There are currently no known cases of this occurring after tocilizumab has been used during breastfeeding. In some countries, vaccines containing live viruses or bacteria might not be given to the infants if tocilizumab was used by the woman when breastfeeding.<br/><br/>';
            KMScontent.breastfeedingdetailsshort = 'In some patients, the benefits of using tocilizumab whilst breastfeeding may outweigh the potential risks. However, the available evidence is currently highly limited, making quantification of these risks difficult. The decision to use or continue tocilizumab whilst breastfeeding should be undertaken jointly by the patient, the prescribing clinician, and the neonatologist/paediatrician after careful discussion of the available evidence and its limitations.<br/><br/>';
            KMScontent.breastfeedingdetails = 'Published experience of tocilizumab exposure during breastfeeding is limited to a small number of uncontrolled case reports/series which together detail at least six mother-infant exposed pairs [1&#45;4].' +
             '<br/><br/>No data has been located concerning the impact of tocilizumab administration on the ability to breastfeed, but there are several case reports detailing successful breastfeeding by exposed patients 1&#45;4].' +
             '<br/><br/>Tocilizumab passes into human breastmilk, with the highest concentrations reported in the first three days after administration [1] [2]. However, tocilizumab has not been detected in infant serum following exposure through breastmilk, but the available data are limited to a report which only included a single mother&#45;infant pair [1].' +
             '<br/><br/>Adverse effects, including any severe infections, have been described among a small number of infants who were breastfed by tocilizumab exposed women [1&#45;4]. Additionally, two infants exclusively breastfed for 9 and 11 months respectively received all routine vaccinations including the BCG vaccine without any adverse effects [1].<br/><br/>';
        }
        /*
        if (this.props.location.state.title == '3') {
            KMScontent.title = '';
            KMScontent.pregnancysummary = '';
            KMScontent.pregnancydetailsshort = '';
            KMScontent.pregnancydetails = '';
            KMScontent.pregnancyreferences = '';
            KMScontent.breastfeedingsummary = '';
            KMScontent.breastfeedingdetailsshort = '';
            KMScontent.breastfeedingdetails = '';
            KMScontent.breastfeedingreferences = '';
        }*/
        return KMScontent;
    }

    render() {

    const { showMore1, showMore2 } = this.state;
    let btnClass1 = 'content-block__more';
    if (showMore1) {
      btnClass1 = 'content-block__more content-block__more--active';
    }
    let btnClass2 = 'content-block__more';
    if (showMore2) {
      btnClass2 = 'content-block__more content-block__more--active';
    }
    const sections = [
      { key: 'Home', content: 'Home', link: true },
      { key: 'Search', content: 'Search result', link: true },
        { key: this.setKMScontent().title, content: this.setKMScontent().title, active: true },
    ];

    return (
      <BaseLayout headerWithShadow={true}>
        <section className='breadcrumbs'>
          <div className='container'>
            <Breadcrumb icon='right angle' sections={sections} />
          </div>
        </section>
        <section className='detail-page'>
          <div className='container'>
               <div className='detail-page__header'>
              <div className='detail-page__ttl'>{this.setKMScontent().title}</div>
              <div className='detail-page__search-bar'>
                  <SearchBar value="" />
              </div>
            </div>
            <div className='detail-page__body'>
                 <div className='detail-page__col1'>
                {this.setKMScontent().showBreastFeeding == true && (
                <div className='page-navigation'>
                  <div className='page-navigation__ttl'>On this Page</div>
                  <ul className='page-navigation__list'>
                    <li className='page-navigation__item'>
                            <a
                                onClick={() => this.scrollToMyRef("prg")}
                                className={this.prgNodeClass}
                            >
                            Pregnancy
                        </a>
                    </li>
                    <li className='page-navigation__item'>
                        <a onClick={() => this.scrollToMyRef("bf")} className={this.bgNodeClass}>
                            Breastfeeding
                        </a>
                    </li>
                  </ul>
                </div>)}
              </div>
              <div className='detail-page__col2'>
                   <div className='content-block' id='pregnancy' ref={this.prgRef}>
                  <div className='content-block__header'>
                    <div className='content-block__ttl'>Pregnancy</div>
                    <div className='content-block__action'>
                      <ChooseTranslation />
                    </div>
                  </div>
                  <div className='content-block__body'>
                                    <div dangerouslySetInnerHTML={{ __html: this.setKMScontent().pregnancysummary }} />
                    <div className='content-block__sub-ttl'>Details</div>
                                    <div dangerouslySetInnerHTML={{ __html: this.setKMScontent().pregnancydetailsshort }} />
                    <Transition
                      visible={showMore1}
                      animation={showMore1 ? 'fade down' : 'fade up'}
                      duration={500}
                    >
                    <div dangerouslySetInnerHTML={{ __html: this.setKMScontent().pregnancydetails }} />
                    </Transition>
                    <a href='/' className={btnClass1} onClick={this.toggle}>
                      {!showMore1 && 'Show more'}
                      {showMore1 && 'Less'}
                    </a>          
                        <References referenceid={this.setKMScontent().title} referencetype='pregnancy' />
                  </div>
                </div>
                        {this.setKMScontent().showBreastFeeding == true && (
                        <div className='content-block'>
                            <div className='content-block__header' id='breastfeeding' ref={this.bfRef}>
                                <div className='content-block__ttl'>Breastfeeding</div>
                                <div className='content-block__action'>
                                    <ChooseTranslation />
                                </div>
                            </div>
                            <div className='content-block__body'>
                                <div dangerouslySetInnerHTML={{ __html: this.setKMScontent().breastfeedingsummary }} />
                                <div className='content-block__sub-ttl'>Details</div>
                                <div dangerouslySetInnerHTML={{ __html: this.setKMScontent().breastfeedingdetailsshort }} />
                                <Transition
                                    visible={showMore2}
                                    animation={showMore2 ? 'fade down' : 'fade up'}
                                    duration={500}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: this.setKMScontent().breastfeedingdetails }} />
                                </Transition>

                                <a href='/' className={btnClass2} onClick={this.toggle2}>
                                    {!showMore2 && 'Show more'}
                                    {showMore2 && 'Less'}
                                </a>
                                <References referenceid={this.setKMScontent().title} referencetype='breastfeeding' />
                            </div>
                        </div>)}
                <div className='content-block'>
                  <div className='content-block__header'>
                    <div className='content-block__ttl'>External links</div>
                  </div>
                  <div className='content-block__body'>
                    <ul className='content-block__link'>
                      <li>
                        <a href='/'>
                          Local knowledge bank of The Netherlands
                          Pharmacovigilance Centre Lareb
                        </a>
                      </li>
                      <li>
                        <a href='/'>
                          National registers of authorised medicines
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='content-block__footer'>
                         DISCLAIMER: this website is currently under development and all information is ONLY for testing and demo purposes. 
                        The information on this website is not reviewed or approved and should not be used as such. 
                    <div className='content-block__published'>
                      Last updated <b>16/04/2020</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className='detail-page__col3'>
                <div className='sidebar-pages sidebar-pages--mb'>
                  <div className='sidebar-pages__ttl'>Related pages</div>
                  <ul className='sidebar-pages__list'>
                    <li className='sidebar-pages__item'>
                      <a href='/' className='sidebar-pages__link'>
                        Nam dapibus nisl vitae elit fringilla rutrum.
                      </a>
                    </li>
                    <li className='sidebar-pages__item'>
                      <a href='/' className='sidebar-pages__link'>
                        Nam dapibus nisl vitae elit fringilla rutrum.
                      </a>
                    </li>
                    <li className='sidebar-pages__item'>
                      <a href='/' className='sidebar-pages__link'>
                        Nam dapibus nisl vitae elit fringilla rutrum.
                      </a>
                    </li>
                  </ul>
                </div>

                <div className='sidebar-pages'>
                  <div className='sidebar-pages__ttl'>Related In focus</div>
                  <ul className='sidebar-pages__list'>
                    <li className='sidebar-pages__item'>
                      <a href='/' className='sidebar-pages__infocus'>
                        <img src={ImgNews} alt='' />
                        Nam dapibus nisl vitae elit fringilla rutrum.
                      </a>
                    </li>
                    <li className='sidebar-pages__item'>
                      <a href='/' className='sidebar-pages__infocus'>
                        <img src={ImgNews} alt='' />
                        Nam dapibus nisl vitae elit fringilla rutrum.
                      </a>
                    </li>
                    <li className='sidebar-pages__item'>
                      <a href='/' className='sidebar-pages__infocus'>
                        <img src={ImgNews} alt='' />
                        Nam dapibus nisl vitae elit fringilla rutrum.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default DetailPage;
