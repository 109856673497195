import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import { Tab } from 'semantic-ui-react';
import TabPages from '../components/TabPages';
import TabMenu from '../components/TabMenu';
import TabOther from '../components/TabOther';

const panes = [
  {
    menuItem: 'Pages',
    render: () => (
      <Tab.Pane attached={false}>
        <TabPages />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Menu',
    render: () => (
      <Tab.Pane attached={false}>
        <TabMenu />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Other',
    render: () => (
      <Tab.Pane attached={false}>
        <TabOther />
      </Tab.Pane>
    ),
  },
];

class Website extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>Website</div>
          </div>
          <div className='app-content__body'>
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={panes}
              className='website-tabs'
            />
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default Website;
