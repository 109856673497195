import React from 'react';

class EditKnowledgePageTabHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className='edit-tab-history'>
        <div className='edit-tab-history__left'>
          <div className='edit-history'>
            <div className='edit-history__ttl'>History</div>
            <div className='edit-history__body'>
              <div className='edit-history__item'>
                <div className='edit-history__name'>
                  <b>Sander Slingerland</b> changed status from In review to
                  Concept
                </div>
                <div className='edit-history__info'>
                  <div className='edit-history__status'>
                    <div className='edit-history__circle'></div>
                  </div>
                  <div className='edit-history__date'>10/10/2019</div>
                </div>
              </div>
              <div className='edit-history__item'>
                <div className='edit-history__name'>
                  <b>Sander Slingerland</b> made changes
                </div>
                <div className='edit-history__info'>
                  <div className='edit-history__status'>
                    <div className='edit-history__keyboard'></div>
                  </div>
                  <div className='edit-history__date'>10/10/2019</div>
                </div>
              </div>
              <div className='edit-history__item edit-history__item--active'>
                <div className='edit-history__name'>
                  <b>Linda Harmark</b> changed status from Concept to In review,
                  made changes
                </div>
                <div className='edit-history__info'>
                  <div className='edit-history__status'>
                    <div className='edit-history__keyboard'></div>
                    <div className='edit-history__circle edit-history__circle--blue'></div>
                  </div>
                  <div className='edit-history__date'>10/10/2019</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='edit-tab-history__right'>
          <div className='history-changes'>
            <div className='history-changes__header'>
              <div className='history-changes__ttl'>
                <b>Linda Harmark</b> changed status from Concept to In review,
                made changes
              </div>
              <div className='history-changes__date'>10/10/2019 10:52</div>
            </div>
            <div className='history-changes__item history-changes__item--inline'>
              <div className='history-changes__sub-ttl'>Status</div>
              <div className='status status--blue'>In review</div>
              <div className='status status--line-trought'>Concept</div>
            </div>
            <div className='history-changes__item'>
              <div className='history-changes__sub-ttl'>Summary</div>
              <div className='history-changes__new'>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip. Duis aute irure dolor in reprehenderit
                in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip. Duis aute irure dolor in reprehenderit
                in voluptate velit esse
              </div>
              <div className='history-changes__old'>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip. Duis aute irure dolor in reprehenderit
                in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditKnowledgePageTabHistory;
