import React from 'react';
import BaseLayout from '../layout/BaseLayout';
import { Breadcrumb, Modal, Icon } from 'semantic-ui-react';
import ImgVideo from '../images/img-video.jpg';
import entisLogo from '../images/entis-logo.jpg';

class HowWeWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const trigger = (
            <div className='about-us__img-video'>
                <img src={ImgVideo} alt='' />
            </div>
        );
        const sections = [
            { key: 'Home', content: 'Home', link: true },
            { key: 'About Us', content: 'About Us', active: true },
        ];
        return (
            <BaseLayout>
                <section className='breadcrumbs'>
                    <div className='container'>
                        <Breadcrumb icon='right angle' sections={sections} />
                    </div>
                </section>
                <section className='about-us'>
                    <div className='container'>
                        <div className='about-us__content'>
                            <div className='about-us__ttl'>How we work</div>
                            <div className='about-us__text'>
                                The information in the knowledge bank has been written by members of the European Network of Teratology Information Services, ENTIS.  The main objective of ENTIS is to collect and evaluate data, and disseminate this information, in order to contribute to the primary prevention of birth defects and developmental disorders.
                                <br /><br />
                                ENTIS consist of individual Teratology Information Services, TIS,   whose primary task is to irth defects caused by teratogens can be prevented. The main task of each TIS is to assess and evaluate risks to pregnancy and breastfeeding outcomes from medications and other exposures recognize and to detect risk factors with the objective of preventing birth defects. 
                                <br /><br />
                                To execute this task each TIS counsels individual cases with exposure to drugs and other exogenous agents during pregnancy with respect to the risk of reproductive toxicity. The information provided is based on current sientific data, which is collected and analyzed by each TIS staff, for a list of local TIS see  <a href="https://www.entis-org.eu/centers" target="_blank">https://www.entis-org.eu/centers</a>.
                                <br /><br />
                                To make the information more widely available, both for pregnant and breastfeeding women as well as health care professionals, ENTIS is closely involved in the knowledge bank. 
                                <br /><br /><br /><br />
                             </div>
                        </div>
                        <div className='about-contact'>
                            <div className='about-contact__ttl'>Contact information</div>
                            <div className='about-contact__content'>
                                <div className='about-contact__left'>
                                    <div className='about-contact__sub-ttl'>
                                        Teratology Information Services Organisations
                  </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                  </p>
                                    <a href='/' className='about-contact__link'>
                                        <Icon name='linkify'></Icon>
                    entis-org.eu
                  </a>
                                </div>
                                <div className='about-contact__right'>
                                    <img src={entisLogo} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BaseLayout>
        );
    }
}

export default HowWeWork;
