import React from 'react';
import { BrowserRouter as Router, NavLink, Link } from 'react-router-dom';
import IcoPartner1 from '../images/img-footer-partner-1.svg';
import IcoPartner2 from '../images/img-footer-partner-2.svg';
import IcoPartner3 from '../images/img-footer-partner-3.svg';
import ConceptionLogo from '../images/conception-logo.png';

function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__row'>
          <div className='footer__logo'>
              <Link to='/'>
                <img src={ConceptionLogo} alt='Conception Logo' />
              </Link>
          </div>
          <div className='ack'>
                      The ConcePTION project has received funding from the <a class="ack_link" href="https://www.imi.europa.eu/" target="_blank" rel="noopener noreferrer">Innovative Medicines Initiative</a> 2 Joint Undertaking under grant agreement No 821520. This Joint Undertaking receives support from the European Union's <a class="ack_link" href="https://ec.europa.eu/programmes/horizon2020" target="_blank" rel="noopener noreferrer">Horizon 2020</a> research and innovation programme and <a class="ack_link" href="https://www.efpia.eu" target="_blank" rel="noopener noreferrer">EFPIA</a>. 

            The development of the knowledge bank was conducted as part of the ConcePTION consortium. This content of the knowledge bank only reflects the personal views of the stated authors. 
          </div>
          <ul className='footer__partners'>
            <li>
              <a href='/'>
                <img src={IcoPartner1} alt='' />
              </a>
            </li>
            <li>
              <a href='/'>
                <img src={IcoPartner2} alt='' />
              </a>
            </li>
            <li>
              <a href='/'>
                <img src={IcoPartner3} alt='' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
