import React from 'react';
import { Link } from 'react-router-dom';

function SearchResultItem(props) {
  return (
    <li className='search-item'>
        <Link to={{
            pathname: '/Detail-page', state: {
                title: props.title
            }
        }} className='search-item__link'>
              <div className='search-item__ttl'>{props.title}</div>
      </Link>
      <p className='search-item__text'>
              {props.contentshort}
      </p>
      <div className='search-item__footer'>
        <span className='search-item__keywords'>Keywords found:</span>
       <span className='label'>{props.keyword}</span>
      </div>
    </li>
  );
}

export default SearchResultItem;
