import React from 'react';
import AdminLayout from '../layout/AdminLayout';
import InFocusRow from '../components/InFocusRow';
import { Link } from 'react-router-dom';

import { Icon, Input, Button, Pagination, Dropdown } from 'semantic-ui-react';

const pages = [
  {
    key: 10,
    text: 10,
    value: 10,
  },
  {
    key: 20,
    text: 20,
    value: 20,
  },
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 'All',
    text: 'All',
    value: 'All',
  },
];

class InFocus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 10 };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (e, { page }) => this.setState({ page });
  render() {
    const { page } = this.state;
    return (
      <AdminLayout>
        <div className='app-content'>
          <div className='app-content__header'>
            <div className='app-content__ttl'>In focus pages</div>
            <Button
              as={Link}
              to='/in-focus-pages/create'
              className='add-in-focus'
              color='teal'
            >
              <Icon name='plus' />
              Create
            </Button>
          </div>
          <div className='app-content__body'>
            <div className='table-in-focus'>
              <div className='table-in-focus__filter'>
                <div className='table-in-focus__search'>
                  <div className='search-filter'>
                    <Input placeholder='Search In focus pages'></Input>
                    <Button color='teal'>Search</Button>
                  </div>
                </div>
              </div>
              <div className='table-in-focus__head'>
                <div className='table-in-focus__tr'>
                  <div className='table-in-focus__td table-in-focus__td--th'>
                    Nr.
                  </div>
                  <div className='table-in-focus__td table-in-focus__td--th'>
                    Page cover
                  </div>
                  <div className='table-in-focus__td table-in-focus__td--th'>
                    Title
                    <div className='sort-icon'>
                      <Icon name='long arrow alternate up' />
                      <Icon name='long arrow alternate down' />
                    </div>
                  </div>
                  <div className='table-in-focus__td table-in-focus__td--th'>
                    Date created
                    <div className='sort-icon'>
                      <Icon name='long arrow alternate up' />
                      <Icon name='long arrow alternate down' />
                    </div>
                  </div>
                  <div className='table-in-focus__td'></div>
                </div>
              </div>
              <div className='table-in-focus__body'>
                <InFocusRow pinned order='1'></InFocusRow>
                <InFocusRow pinned order='2'></InFocusRow>
                <InFocusRow pinned order='3'></InFocusRow>
                <InFocusRow pinned order='4'></InFocusRow>
                <InFocusRow order='5'></InFocusRow>
                <InFocusRow order='6'></InFocusRow>
                <InFocusRow order='7'></InFocusRow>
                <InFocusRow order='8'></InFocusRow>
              </div>
            </div>

            <div className='table-pagination'>
              <span className='table-pagination__text'>Showing results:</span>
              <Dropdown
                selection
                options={pages}
                onChange={this.handleChange}
                value={page}
              />
              <span className='table-pagination__text'>Page</span>
              <Pagination
                activePage={1}
                boundaryRange={0}
                siblingRange={1}
                totalPages={22}
                ellipsisItem={undefined}
                firstItem={null}
                lastItem={null}
                prevItem={undefined}
                nextItem={undefined}
              />
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default InFocus;
