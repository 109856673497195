import React from 'react';
import { Icon, Input, Button } from 'semantic-ui-react';

class TranslationsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      lang: 'En',
      email: 'info@lareb.nl',
      isOpen: false,
    };
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.handleChangeLang = this.handleChangeLang.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle(e) {
    e.preventDefault();
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }
  handleChangeLang(event) {
    this.setState({ lang: event.target.value });
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  edit(e) {
    e.preventDefault();
    this.setState({ isEdit: true });
  }
  save(e) {
    e.preventDefault();
    this.setState({ isEdit: false });
  }
  render() {
    const { isEdit, lang, email, isOpen } = this.state;
    return (
      <div>
        <div className='table-translations__tr'>
          <div className='table-translations__td'>
            <div className='table-translations__lang' onClick={this.toggle}>
              <span className='table-translations__mobile-ttl'>Name</span>
              {!isEdit && lang}
              {isEdit && (
                <Input
                  value={this.state.lang}
                  onChange={this.handleChangeLang}
                />
              )}
            </div>
          </div>
          <div className='table-translations__td'>
            <span className='table-translations__mobile-ttl'>Email</span>

            {!isEdit && email}
            {isEdit && (
              <Input
                value={this.state.email}
                onChange={this.handleChangeEmail}
              />
            )}

            <div className='only-mobile'>
              {isEdit && (
                <div>
                  <Button basic onClick={this.save}>
                    Cancel
                  </Button>
                  <Button color='teal' onClick={this.save}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className='table-translations__td'>
            {!isEdit && (
              <div className='table-translations__edit-btn'>
                <Button icon onClick={this.edit}>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            )}
            <div className='only-desktop'>
              {isEdit && (
                <div>
                  <button
                    className='table-medical-conditions__cancel'
                    onClick={this.save}
                  >
                    Cancel
                  </button>
                  <button
                    className='table-medical-conditions__save'
                    onClick={this.save}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <div>
            <div className='table-translations__tr table-translations__tr--additional'>
              <div className='table-translations__td'>
                <button className='table-translations__add'>
                  <Icon name='plus' />
                  Add hyperlink
                </button>
              </div>
            </div>
            <div className='table-translations__tr table-translations__tr--additional'>
              <div className='table-translations__td'>
                <a href='/' className='table-translations__link'>
                  <Icon color='teal' name='linkify' />
                  <span>https://www.geneesmiddeleninformatiebank.nl/en</span>
                </a>
              </div>
              <div className='table-translations__td'>
                CBG Geneesmiddelen informatie
              </div>
              <div className='table-translations__td'>
                <Button icon>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            </div>
            <div className='table-translations__tr table-translations__tr--additional'>
              <div className='table-translations__td'>
                <a href='/' className='table-translations__link'>
                  <Icon color='teal' name='linkify' />
                  https://www.lareb.nl/tis-knowledge
                </a>
              </div>
              <div className='table-translations__td'>Dutch knowledge bank</div>
              <div className='table-translations__td'>
                <Button icon>
                  <Icon color='teal' name='pencil' />
                </Button>
                <Button icon>
                  <Icon color='teal' name='trash alternate outline' />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TranslationsRow;
